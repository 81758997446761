import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getPathAndParameter } from './common/util/trackingUtils';
import UserEvents from './UserEvents';

export const withTracker = <P extends Record<string, unknown>>(WrappedComponent: React.ComponentType<P>) => (
    props: P,
): JSX.Element => {
    const history = useHistory();

    const trackPage = (page: string) => {
        UserEvents.viewPage(getPathAndParameter(page));
    };

    useEffect(() => {
        trackPage(history.location.pathname);
    }, [history.location.pathname]);

    return <WrappedComponent {...props} />;
};
