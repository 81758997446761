import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div`
    width: 100%;
`;

const Heading = styled.h2`
    border: none;
    font-weight: 500;
    color: #000000;
    margin: 0;
    font-size: 24px;
    padding: 0 0 12px 0;
    @media (min-width: 768px) {
        font-size: 32px;
        padding: 0 0 16px 0;
    }
`;

interface DialogHeaderProps {
    text: string;
}

const DialogHeader: React.FC<DialogHeaderProps> = ({ text }) => {
    return (
        <Container>
            <Heading>{text}</Heading>
        </Container>
    );
};

export default DialogHeader;
