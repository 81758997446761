import styled from '@emotion/styled';

export const Columns = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    gap: 32px;
    @media (min-width: 768px) {
        gap: 32px;
    }
    @media (min-width: 992px) {
        gap: 40px;
    }
`;

export const Column = styled.div`
    flex: 1;
    min-width: 280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const FooterActions = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;

    gap: 16px;

    @media (min-width: 768px) {
        gap: 32px;
    }
    @media (min-width: 992px) {
        gap: 40px;
    }
`;

export const CancelButton = styled.button`
    flex: 1;
    min-width: 280px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 0;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    background: transparent;
    border: 2px solid #2b2d3f;
    color: #2b2d3f;
    transition: background 0.2s, color 0.2s;
    &:disabled {
        border-color: #f2f4f8;
        color: #c4c7d0;
        pointer-events: none;
        cursor: not-allowed;
    }
`;

export const SaveButton = styled.button`
    flex: 1;
    min-width: 280px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 0;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    background: #2b2d3f;
    border: none;
    color: #ffffff;
    transition: background 0.2s, color 0.2s;
    &:disabled {
        background: #f2f4f8;
        color: #c4c7d0;
        border-color: #f2f4f8;
        pointer-events: none;
        cursor: not-allowed;
    }
`;
