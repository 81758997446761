import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQueryParam } from 'use-query-params';
import { isAdmin as checkIsAdmin } from 'wavepaths-shared/domain/user';

import { Button } from '@/component-library';
import Confirm from '@/component-library/components/Dialog';
import PageHeading from '@/component-library/components/PageHeading';
import TypographyV2 from '@/component-library/typography/TypographyV2';

import { useAuthContext } from '../../auth';
import { SupportPostUpsertData } from '../../common/api/supportPostsApi';
import { LayoutContainer } from '../../LayoutContainer';
import { SupportPost } from './SupportPost';
import { SupportPostForm, SupportPostFormState } from './SupportPostForm';
import {
    useSupportArchivePost,
    useSupportCreatePost,
    useSupportPosts,
    useSupportUnarchivePost,
    useSupportUpdatePost,
} from './useSupportPosts';

const Container = styled.div``;

const Description = styled.div`
    margin-bottom: 32px;
`;

const AdminActions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    gap: 16px;
`;

const Tag = styled.div`
    display: flex;
    align-items: center;
    background-color: #ff9800;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
`;

const Content = styled.div`
    max-width: 700px;
    margin: 0 auto;
`;

const open = () => ({ isOpen: true });
const openPost = (id: string) => ({ isOpen: true, postId: id });
const closed = () => ({ isOpen: false });

function SupportPostsContainer() {
    const { userData } = useAuthContext();
    const isAdmin = !!userData && checkIsAdmin(userData);
    const [formDialogState, setFormDialogState] = useState<{ isOpen: boolean; postId?: string }>(closed);
    const [confirmArchiveState, setConfirmArchiveState] = useState<{ isOpen: boolean; postId?: string }>(closed);
    const [confirmUnarchiveState, setConfirmUnarchiveState] = useState<{ isOpen: boolean; postId?: string }>(closed);
    const [isIncludeArchived, setIsIncludeArchived] = useState(isAdmin);

    const { supportPosts, isLoading, refresh } = useSupportPosts({
        includeArchived: isIncludeArchived,
    });
    const { mutate: create } = useSupportCreatePost({
        onSuccess: refresh,
    });
    const { mutate: update } = useSupportUpdatePost({
        onSuccess: refresh,
    });
    const { mutate: archive } = useSupportArchivePost({
        onSuccess: refresh,
    });
    const { mutate: unarchive } = useSupportUnarchivePost({
        onSuccess: refresh,
    });

    const [post, setPost] = useQueryParam<string | undefined>('post');

    const postSelected = (supportPosts || []).find((n) => n.id === post);

    const onSubmitDialog = (post: SupportPostFormState) => {
        const data: SupportPostUpsertData = {
            tag: post.tag,
            description: post.description,
            shortDescription: post.shortDescription,
            publishedAt: post.date?.toISOString(),
        };
        const promise = formDialogState.postId ? update(formDialogState.postId, data) : create(data);
        promise.then(() => setFormDialogState(closed)).catch((e) => console.error(e));
    };

    const onArchive = (id: string) => {
        archive(id)
            .then(() => setConfirmArchiveState(closed))
            .catch((e) => console.error(e));
    };

    const onUnarchive = (id: string) => {
        unarchive(id)
            .then(() => setConfirmUnarchiveState(closed))
            .catch((e) => console.error(e));
    };

    const postInForm = useMemo(() => {
        if (!formDialogState.isOpen) {
            return undefined;
        }
        return supportPosts?.find((n) => n.id === formDialogState.postId) || undefined;
    }, [formDialogState.isOpen, formDialogState.postId, supportPosts]);

    return (
        <Content>
            <PageHeading text={'Customer Support'} />
            <Description>
                <TypographyV2 color="grey-600" size="text-sm">
                    Welcome to our support page. Here, you'll find a variety of resources designed to help you
                    troubleshoot potential technical issues. If you don’t find the solution you need, or if the
                    recommended steps don’t resolve your issue, please reach out to us at{' '}
                    <a href="mailto:support@wavepaths.com">support@wavepaths.com</a>. One of our dedicated team members
                    will get back to you as quickly as possible.
                </TypographyV2>
            </Description>

            {isAdmin && (
                <AdminActions>
                    <Button variant="solid-blue" onClick={() => setFormDialogState(open)}>
                        Add new
                    </Button>

                    {isIncludeArchived ? (
                        <Button variant="solid-blue" onClick={() => setIsIncludeArchived(false)}>
                            Hide archived
                        </Button>
                    ) : (
                        <Button variant="solid-blue" onClick={() => setIsIncludeArchived(true)}>
                            Show archived
                        </Button>
                    )}

                    {formDialogState.isOpen ? (
                        <Dialog open={true} onClose={() => setFormDialogState(closed)} fullWidth={true} maxWidth={'md'}>
                            <DialogTitle>Add new Post</DialogTitle>
                            <DialogContent>
                                <SupportPostForm initialState={postInForm} onSubmit={onSubmitDialog} />
                            </DialogContent>
                        </Dialog>
                    ) : (
                        <></>
                    )}

                    <Confirm
                        fullWidth={true}
                        open={confirmArchiveState.isOpen}
                        message={'Are you sure you want to archive this post?'}
                        onClose={() => setConfirmArchiveState(closed)}
                        onConfirm={() => confirmArchiveState.postId && onArchive(confirmArchiveState.postId)}
                        confirmText={'Yes, ARCHIVE'}
                    />

                    <Confirm
                        fullWidth={true}
                        open={confirmUnarchiveState.isOpen}
                        message={'Are you sure you want to restore this post?'}
                        onClose={() => setConfirmUnarchiveState(closed)}
                        onConfirm={() => confirmUnarchiveState.postId && onUnarchive(confirmUnarchiveState.postId)}
                        confirmText={'Yes, RESTORE'}
                    />
                </AdminActions>
            )}

            <Container>
                {postSelected && (
                    <SupportPost
                        key={postSelected.id}
                        post={postSelected}
                        onClick={() => setPost(undefined)}
                        isExpanded
                    />
                )}

                {isLoading && <>Loading...</>}

                {!postSelected &&
                    !isLoading &&
                    supportPosts.map((n) => (
                        <SupportPost key={n.id} post={n} onClick={() => setPost(n.id)}>
                            {isAdmin && (
                                <AdminActions>
                                    {isAdmin && n.isArchived && (
                                        <Tag>
                                            <TypographyV2 size="text-sm">Archived</TypographyV2>
                                        </Tag>
                                    )}

                                    <Button variant="solid-blue" onClick={() => setFormDialogState(openPost(n.id))}>
                                        Edit
                                    </Button>

                                    {n.isArchived ? (
                                        <Button
                                            variant="solid-blue"
                                            onClick={() => setConfirmUnarchiveState(openPost(n.id))}
                                        >
                                            Restore
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="solid-blue"
                                            onClick={() => setConfirmArchiveState(openPost(n.id))}
                                        >
                                            Archive
                                        </Button>
                                    )}
                                </AdminActions>
                            )}
                        </SupportPost>
                    ))}

                {!supportPosts.length && !isLoading && !postSelected && (
                    <TypographyV2>No customer support available</TypographyV2>
                )}
            </Container>
        </Content>
    );
}

function SupportPostsWithNav() {
    return (
        <LayoutContainer>
            <SupportPostsContainer />
        </LayoutContainer>
    );
}

export default SupportPostsWithNav;
