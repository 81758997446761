import React from 'react';
import { Session, SessionHeader, SessionType } from 'wavepaths-shared/core';

// TODO: this can return a string that says "undefined"
export const renderSessionName = (session: Session | SessionHeader): string =>
    String(session.variableInputs?.name || session.variableInputs?.nameOnWebsite || session.score?.name);

export const renderSessionClients = (session: Session | SessionHeader): string => {
    return (session.clients ?? []).map((x) => `${x.firstname ?? ''} ${x.lastname ?? ''}`).join(', ');
};

export const renderSessionType = (type: SessionType): string => {
    switch (type) {
        case 'groupGuided':
            return 'Guided group';
        case 'groupInfinite':
            return 'Infinite group';
        case 'oneOnOne':
            return 'Music-Guided';
        case 'intro':
            return 'Intro';
        default:
            return '';
    }
};

// TODO: Investigate potenential backward compatibility issues
//        Old session data will have `firstName` and `lastName` while new data will have just `name`
export const renderSessionUser = (session: Session): JSX.Element | null =>
    session.user ? (
        <>
            {session.user.name} &lt;
            {session.user.email}&gt;
        </>
    ) : null;
