import 'firebase/auth';
import 'firebase/database';

import * as Sentry from '@sentry/browser';
import { isSameMinute } from 'date-fns';
import firebase from 'firebase/app';
import { isNull } from 'lodash';
import { createContext, useContext } from 'react';
import { UserData, UserRoles } from 'wavepaths-shared/core';

import * as bonnyApi from './common/api/bonnyApi';
import configs from './configs';
import UserEvents from './UserEvents';
import { getUtmTagsFromStorage } from './utmUtils';

const emailsRedirectUrlConfig = {
    prod: 'https://guide.wavepaths.com/login',
    staging: 'https://guide-staging.wavepaths.com/login',
    dev: 'https://guide-dev.wavepaths.com/login',
    'dev-local': 'http://localhost:8081/',
};

export const firebaseEmailsRedirectUrl = emailsRedirectUrlConfig[configs.freud.ENV];

export const AuthContext = createContext({
    user: undefined,
    firebaseUser: undefined,
    isPersonal: false,
    isEnabled: () => false,
} as { firebaseUser?: firebase.User; userData?: UserData; isPersonal: boolean; isEnabled: (feature: string) => boolean });

export const useAuthContext = (): {
    firebaseUser?: firebase.User;
    userData?: UserData;
    isPersonal: boolean;
    isEnabled: (feature: string) => boolean;
} => useContext(AuthContext);

// check features
// if ok, sign up with FB
// post /signUp (copies over data where necessary)

export const setUrlParamsToError = (): void => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('error', 'true');
    window.location.search = urlParams.toString();
};

export type SignUpStatus = 'alreadySignedUp' | 'error' | 'success' | 'weakPassword' | 'invalidEmail';

export const signUpV2 = async (
    name: string,
    email: string,
    password: string,
    userData?: { roles: UserRoles[]; groups: string[]; client_id?: string },
): Promise<{
    status: SignUpStatus;
    user?: firebase.User;
}> => {
    let user: firebase.User | null;

    try {
        const authResponse = await firebase.auth().createUserWithEmailAndPassword(email, password);
        user = authResponse.user;
    } catch (error) {
        // TODO: make function typesafe, because error type can be Error or AuthError
        const authError = error as firebase.auth.AuthError;

        switch (authError.code) {
            case 'auth/email-already-in-use':
                return { status: 'alreadySignedUp' };
            case 'auth/weak-password':
                return { status: 'weakPassword' };
            case 'auth/invalid-email':
                return { status: 'invalidEmail' };
            default:
                Sentry.withScope((scope) => {
                    scope.setExtra('email', email);
                    scope.setExtra('id', user?.uid);
                    Sentry.captureException('Unhandled auth error');
                });
                return { status: 'error' };
        }
    }

    if (!user) return { status: 'error' };

    const utmTags = getUtmTagsFromStorage();

    try {
        await bonnyApi.signUp(user, { name, utmTags, ...userData });
    } catch (error) {
        switch ((error as Error).message) {
            default:
                Sentry.withScope((scope) => {
                    scope.setExtra('email', email);
                    scope.setExtra('id', user?.uid);
                    Sentry.captureException('UnknownSignUpError');
                });
                await firebase.auth().signOut();
                setUrlParamsToError();
                return { status: 'error' };
        }
    }

    try {
        await user.sendEmailVerification({ url: firebaseEmailsRedirectUrl });
    } catch (e) {
        // do nothing
    }

    UserEvents.signUp();

    if (userData?.client_id) {
        await bonnyApi.correlateAuthUserWithClient(user, { client_id: userData?.client_id });
    }

    return { status: 'success', user };
};

export type SignInStatus = 'success' | 'userNotFound' | 'wrongPassword' | 'userDisabled' | 'error';

export async function signIn(
    email: string,
    password: string,
): Promise<{
    status: SignInStatus;
    user?: firebase.User;
}> {
    try {
        const { user: userOrNull } = await firebase.auth().signInWithEmailAndPassword(email, password);
        const user = userOrNull || undefined;
        if (!user) throw new Error();
        UserEvents.signIn();

        const userDataSnapshot = await firebase.database().ref(`users/${user?.uid}`).once('value');
        const userData = userDataSnapshot.val();
        if (user.email && isNull(userData)) {
            Sentry.withScope((scope) => {
                scope.setExtra('email', user.email);
                scope.setExtra('id', user?.uid);
                Sentry.captureException('DBDataMissingOnLogin');
            });
            throw new Error();
        }
        if (userData?.client_id) {
            await bonnyApi.correlateAuthUserWithClient(user, { client_id: userData?.client_id });
        }
        return { status: 'success', user };
    } catch (error) {
        const authError = error as firebase.auth.AuthError;
        if (authError.code === 'auth/user-not-found') {
            return { status: 'userNotFound' };
        } else if (authError.code === 'auth/wrong-password') {
            return { status: 'wrongPassword' };
        } else if (authError.code === 'auth/user-disabled') {
            return { status: 'userDisabled' };
        } else {
            return { status: 'error' };
        }
    }
}

export type SignWithProviderStatus =
    | 'wrongSignInMethod'
    | 'tooManyPopUps'
    | 'popUpBlocked'
    | 'popUpClosedByUser'
    | 'error'
    | 'success';

export const signInWithProviderV2 = async (
    service?: 'fb' | 'google',
    userData?: { roles: UserRoles[]; groups: string[]; client_id?: string },
): Promise<{
    status: SignWithProviderStatus;
    user?: firebase.User;
}> => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    const facebookProvider = new firebase.auth.FacebookAuthProvider();
    let user: firebase.User | null;
    let displayName;
    try {
        const authResponse = await firebase
            .auth()
            .signInWithPopup(service === 'fb' ? facebookProvider : googleProvider);
        user = authResponse.user;
        if (user && user.email) {
            displayName =
                user.providerData.find((profile) => profile && profile.displayName)?.displayName || 'New User';
        } else {
            return { status: 'error' };
        }
    } catch (error) {
        const authError = error as firebase.auth.AuthError;
        switch (authError.code) {
            case 'auth/account-exists-with-different-credential':
                return { status: 'wrongSignInMethod' };
            case 'auth/cancelled-popup-request':
                return { status: 'tooManyPopUps' };
            case 'auth/popup-blocked':
                return { status: 'popUpBlocked' };
            case 'auth/popup-closed-by-user':
                return { status: 'popUpClosedByUser' };
            default:
                return { status: 'error' };
        }
    }
    if (
        user.metadata.creationTime &&
        user.metadata.lastSignInTime &&
        //TODO: MK20240404 find and kill the person who wrote this, if it was me - I quit
        isSameMinute(new Date(user.metadata.creationTime), new Date(user.metadata.lastSignInTime)) &&
        user
    ) {
        const utmTags = getUtmTagsFromStorage();

        try {
            await bonnyApi.signUp(user, { name: displayName, utmTags, ...userData! });
        } catch (error) {
            switch (error) {
                default:
                    Sentry.withScope((scope) => {
                        scope.setExtra('email', user?.email);
                        scope.setExtra('id', user?.uid);
                        Sentry.captureException('UnknownSignUpError');
                    });
                    setUrlParamsToError();
                    return { status: 'error' };
            }
        }

        UserEvents.signUp();
    } else {
        UserEvents.signIn();
    }

    // verify and fix if user data is missing
    try {
        const userDataSnapshot = await firebase.database().ref(`users/${user.uid}`).once('value');
        const userData = userDataSnapshot.val();
        if (user && user.email && isNull(userData)) {
            Sentry.withScope((scope) => {
                scope.setExtra('email', user?.email);
                scope.setExtra('id', user?.uid);
                Sentry.captureException('DBDataMissingOnLogin');
            });
            throw new Error();
        }
    } catch (error) {
        Sentry.withScope((scope) => {
            scope.setExtra('email', user?.email);
            scope.setExtra('id', user?.uid);
            Sentry.captureException('FixingUserDataFailed');
        });
        await firebase.auth().signOut();
        setUrlParamsToError();
        return { status: 'error' };
    }

    if (userData?.client_id) {
        await bonnyApi.correlateAuthUserWithClient(user, { client_id: userData?.client_id });
    }

    return { status: 'success', user };
};

// You cant just "fix" missing data heh ? With what?
// export const fixMissingDBData = async ({
//     firebaseUser,
//     name,
// }: {
//     firebaseUser: firebase.User;
//     name: string;
// }): Promise<void> => {
//     try {
//         await bonnyApi.signUp(firebaseUser, { name });
//     } catch (error) {
//         Sentry.withScope((scope) => {
//             scope.setExtra('email', firebaseUser.email);
//             scope.setExtra('id', firebaseUser?.uid);
//             Sentry.captureException('UnknownSignUpErrorOnDBFixAttempt');
//         });
//         await firebase.auth().signOut();
//         setUrlParamsToError();
//     }
// };

export async function signOut(): Promise<void> {
    UserEvents.signOut();
    await firebase.auth().signOut();
}

export type ResetPasswordStatus = 'success' | 'userNotFound' | 'invalidEmail' | 'error';

export const resetPassword = async (email: string): Promise<ResetPasswordStatus> => {
    try {
        await firebase.auth().sendPasswordResetEmail(email, {
            url: firebaseEmailsRedirectUrl,
        });
        return 'success';
    } catch (error) {
        const authError = error as firebase.auth.AuthError;
        switch (authError.code) {
            case 'auth/invalid-email':
                return 'invalidEmail';
            case 'auth/user-not-found':
                return 'userNotFound';
            default:
                return 'error';
        }
    }
};

export type UpdateEmailStatus = 'success' | 'userNotFound' | 'invalidEmail' | 'email-in-use' | 'requires-recent-login';
export const updateEmail = async (fbUser: firebase.User, email: string) => {
    const oldEmail = fbUser.email!;
    try {
        await fbUser.updateEmail(email);
    } catch (e) {
        Sentry.captureException(e);
        const authError = e as firebase.auth.AuthError;
        switch (authError.code) {
            case 'auth/requires-recent-login':
                return 'requires-recent-login';
            case 'auth/invalid-email':
                return 'invalidEmail';
            case 'auth/email-already-in-use':
                return 'email-in-use';
            default:
                return 'error';
        }
    }

    try {
        await bonnyApi.setMyEmail(fbUser, email);
    } catch (e) {
        await fbUser.updateEmail(oldEmail);
        Sentry.captureException(e);
        return 'error';
    }

    return 'success';
};
