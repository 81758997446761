import React, { Ref } from 'react';
import styled from 'styled-components';

import { PIXELS_PER_MILLIS } from './constants';
import { usePlayheadPositionV2 } from './usePlayheadPosition';

const HEIGHT = 40;

interface TimelinePlayheadProps {
    elapsedTimeMs: number;
    sessionDurationMs: number;
    timelineHeight: number;
    playheadRef: Ref<SVGSVGElement> | undefined;
}
const SVG = styled.svg.attrs<{
    timelineHeight: number;
    playheadPosition: number;
}>(({ timelineHeight, playheadPosition }) => ({
    style: { height: timelineHeight + 74, left: playheadPosition + 22 },
}))<{
    timelineHeight: number;
    playheadPosition: number;
}>`
    overflow: visible;
    cursor: pointer;
    will-change: transform;
    width: 5px;
    z-index: 2;
    position: absolute;
    top: 0px;
`;

export const TimelinePlayhead: React.FC<TimelinePlayheadProps> = React.memo(
    React.forwardRef(({ elapsedTimeMs, sessionDurationMs, timelineHeight, playheadRef }) => {
        const playheadPosition = usePlayheadPositionV2({
            pixelsPerMillis: PIXELS_PER_MILLIS,
            sessionDurationMs,
            elapsedTimeMs,
        });

        return (
            <SVG
                ref={playheadRef}
                timelineHeight={timelineHeight}
                playheadPosition={playheadPosition}
                viewBox={`0 0 5 ${timelineHeight}`}
                preserveAspectRatio="none"
            >
                <rect cx={0} cy={0} height={HEIGHT} width={3} fill={'#FF0000'} />
            </SVG>
        );
    }),
);
