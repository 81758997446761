import { ReactNode, useCallback, useRef, useState } from 'react';

export type UseSnackbarReturn = {
    content: ReactNode | null;
    actionText?: string;
    actionCallback?: () => void;
    showCloseButton?: boolean;
    setSnackbarContent: (
        content: ReactNode | null,
        onDismiss?: () => void,
        actionText?: string,
        actionCallback?: () => void,
        showCloseButton?: boolean,
        timeout?: number, // Add timeout parameter
    ) => void;
    closeSnackbar: () => void;
};

export const useSnackbar = (): UseSnackbarReturn => {
    const [snackbarState, setSnackbarState] = useState<{
        content: ReactNode | null;
        onDismiss?: () => void;
        actionText?: string;
        actionCallback?: () => void;
        showCloseButton?: boolean;
    }>({
        content: null,
    });

    const showTimeout = useRef<NodeJS.Timeout | null>(null);

    const closeSnackbar = useCallback(() => {
        setSnackbarState({ content: null });
        snackbarState.onDismiss && snackbarState.onDismiss();
    }, [snackbarState]);

    const setSnackbarContentHandler = useCallback(
        (
            content: ReactNode | null,
            onDismiss?: () => void,
            actionText?: string,
            actionCallback?: () => void,
            showCloseButton?: boolean,
            timeout = 5000,
        ) => {
            if (showTimeout.current) clearTimeout(showTimeout.current);
            setSnackbarState({ content, onDismiss, actionText, actionCallback, showCloseButton });
            showTimeout.current = setTimeout(() => {
                onDismiss && onDismiss();
                setSnackbarState({ content: null });
            }, timeout);
        },
        [],
    );

    return {
        content: snackbarState.content,
        actionText: snackbarState.actionText,
        actionCallback: snackbarState.actionCallback,
        showCloseButton: snackbarState.showCloseButton,
        setSnackbarContent: setSnackbarContentHandler,
        closeSnackbar,
    };
};
