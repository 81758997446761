import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div`
    background: #fef0c7;
    border-radius: 12px;
    padding: 16px;
`;

const Text = styled.p`
    padding: 0;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #475467;
`;

const Link = styled.a`
    color: #475467;
    text-decoration: underline;
`;

export default function UpgradeMessage({ upgradeLink }: { upgradeLink: string }): React.ReactElement {
    return (
        <Container>
            <Text>
                Please <Link href={upgradeLink}>upgrade your subscription</Link> to access this feature.
            </Text>
        </Container>
    );
}
