import styled from '@emotion/styled';
import CodeIcon from '@material-ui/icons/CodeRounded';
import PlayIcon from '@material-ui/icons/HearingRounded';
import { format } from 'date-fns';
import formatDuration from 'format-duration';
import { isNumber, range, startCase } from 'lodash';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import {
    ActivateVoiceoverEvent,
    AddStageContentEvent,
    AnonymousUser,
    EndOfSessionFeedback,
    EnteredStageEvent,
    EnterStageEvent,
    FailedToFindStageContentEvent,
    FirebaseUser,
    LegacyMidSessionFeedback,
    RecordSessionFeedbackEvent,
    SessionContentSwitchConfig,
    SessionScore,
    SessionStagePreset,
    TimestampedSessionEvent,
} from 'wavepaths-shared/core';

import { Button, Typography } from '@/component-library';
import { GenericErrorBoundary } from '@/components/GenericErrorBoundary';

import { SessionLogLayerTable } from './SessionLogLayerTable';
import { formatPathName, getCEAColourAtIndex, getRelativeTime } from './sessionLogUtils';

interface SessionLogEventProps {
    log: TimestampedSessionEvent[];
    index: number;
    isPastEvent: boolean;
    score: SessionScore;
    onPlayEventAudio: (index: number) => void;
}
export const SessionLogEvent: React.FC<SessionLogEventProps> = React.memo(
    ({ log, index, isPastEvent, score, onPlayEventAudio }) => {
        const event = log[index];

        const getRowColour = () => {
            return getCEAColourAtIndex(index, log);
        };

        const [expanded, setExpanded] = useState(false);
        const toggleExpanded = () => setExpanded((e) => !e);

        return (
            <Container isPastEvent={isPastEvent}>
                <Time rowColour={getRowColour()}>
                    <Typography variant="body2">{format(event.timestamp, 'HH:mm:ss')}</Typography>
                    <Typography variant="body3">+{formatDuration(getRelativeTime(event, log))}</Typography>
                </Time>
                <Actions>
                    <Button size="xs" icon={<PlayIcon fontSize="small" />} onClick={() => onPlayEventAudio(index)} />
                    <Button size="xs" icon={<CodeIcon fontSize="small" />} onClick={toggleExpanded} />{' '}
                </Actions>
                <Content variant="body2" component="div">
                    <GenericErrorBoundary>
                        {getEventDescription(log, index, score)}
                        {expanded && (
                            <ReactJson src={event} displayDataTypes={false} collapsed={1} style={{ marginTop: 16 }} />
                        )}
                    </GenericErrorBoundary>
                </Content>
            </Container>
        );
    },
);
type LegacyRequestManualStage = {
    event: 'requestManualStage';
    sessionStageIndex: any;
    stagePreset: SessionStagePreset;
    contentSwitch?: SessionContentSwitchConfig;
    willBeEnteredIn: number;
    nextContentSwitchAt?: number;
};

type LegacyRequestContentSwitch = {
    event: 'requestContentSwitch';
};

type LegacyToggleManualControl = {
    event: 'toggleManualControl';
    manual: boolean;
};

type LegacyToggleAutoGuide = {
    event: 'toggleAutoGuide';
    auto: boolean;
};

type LegacySessionEvent =
    | LegacyRequestManualStage
    | LegacyRequestContentSwitch
    | LegacyToggleManualControl
    | LegacyToggleAutoGuide;

type LegacyTimestampedSessionEvent = LegacySessionEvent & { timestamp: number };

// TODO: move legacy types here
function getEventDescription(
    log: (TimestampedSessionEvent | LegacyTimestampedSessionEvent)[],
    index: number,
    score: SessionScore,
) {
    const evt = log[index];
    switch (evt.event) {
        case 'startSession':
            return <>Starting session.</>;
        case 'startSessionTimers':
            return <>Starting timers.</>;
        case 'requestManualStage':
            return (
                <>
                    User requested transition to{' '}
                    {isNumber(evt.sessionStageIndex) // Legacy logs
                        ? (score as any).session[evt.sessionStageIndex].name ||
                          `${(score as any).session[evt.sessionStageIndex].preset} ${
                              (score as any).session[evt.sessionStageIndex].stage
                          }`
                        : //@ts-ignore
                          `${evt.sessionStageIndex.preset} ${evt.sessionStageIndex.stage}`}
                    .{' '}
                    {evt.contentSwitch?.musicAttributeTargets && (
                        <>
                            Valence {evt.contentSwitch.musicAttributeTargets['Valence Potential'].toFixed(2)} , Arousal{' '}
                            {evt.contentSwitch.musicAttributeTargets.Arousal.toFixed(2)}
                        </>
                    )}
                    .
                </>
            );
        case 'enterStage':
            return <>Entering {getStageName(evt, score)}.</>;
        case 'addStageContent':
            return (
                <>
                    Set layers for {getStageName(evt, score)}.
                    <SessionLogLayerTable log={log as TimestampedSessionEvent[]} atEventIndex={index} />
                </>
            );
        case 'failedToFindStageContent':
            return <>Failed to find any valid content for {getStageName(evt, score)}.</>;
        case 'enteredStage':
            return <>Now fully entered into {getStageName(evt, score)}.</>;
        case 'activateVoiceover':
            return (
                <>
                    Activating voiceover <em>{getVoiceoverName(evt, score)}</em>.{' '}
                </>
            );
        case 'requestContentSwitch':
            return <>Requested content switch.</>;
        case 'switchContent':
            return (
                <>
                    Initiating content switch, requesting new content from sample library.{' '}
                    {evt.contentSwitch && (
                        <>
                            Switching {JSON.stringify(evt.contentSwitch.numberOfLayersToSwitch)} layers of{' '}
                            {evt.contentSwitch.layers?.join(', ')}{' '}
                        </>
                    )}
                    {evt.contentSwitch?.musicAttributeTargets && (
                        <>
                            at: Valence {evt.contentSwitch.musicAttributeTargets['Valence Potential'].toFixed(2)} ,
                            Arousal {evt.contentSwitch.musicAttributeTargets.Arousal.toFixed(2)}
                        </>
                    )}
                </>
            );
        case 'switchedContent':
            return <>Now fully switched to new content.</>;
        case 'toggleManualControl':
            return evt.manual ? <>Manual control on</> : <>Manual control off</>;
        case 'toggleAutoGuide':
            return evt.auto ? <>Auto Guide on</> : <>Auto Guide off</>;
        case 'reviseSessionPlan':
            return <>Revised timing plan</>;
        case 'recordSessionFeedback':
            return renderFeedback(evt);
        case 'userConnected':
            return <>User connected to stream: {evt.user ? renderFbUser(evt.user) : <>Anonymous</>}</>;
        case 'userDisconnected':
            return <>User disconnected from stream: {evt.user ? renderFbUser(evt.user) : <>Anonymous</>}</>;
        case 'controlUserConnected':
            return <>Control user connected to stream: {evt.user.email}</>;
        case 'controlUserDisconnected':
            return <>Control user disconnected from stream: {evt.user.email}</>;
        case 'stopSession':
            return <>End session</>;
        case 'stoppedSession':
            return <>Ended session</>;
        case 'ceaChangeStarted':
            return (
                <>
                    CEA change to <em>{evt.targetCea}</em> started
                </>
            );
        case 'ceaChangeCompleted':
            return <>CEA change to {evt.cea} completed</>;
        case 'depthChangeStarted':
            return (
                <>
                    Intensity change{' '}
                    {isNumber(evt.previousDepth) ? (
                        <>
                            from <em>{evt.previousDepth}</em>
                        </>
                    ) : (
                        ''
                    )}{' '}
                    to <em>{evt.targetDepth}</em> started
                </>
            );
        case 'depthChangeCompleted':
            return (
                <>
                    Intensity change to <em>{evt.depth}</em> completed
                </>
            );
        case 'instrumentRefreshStarted':
            return <>Instrument refresh started</>;
        case 'instrumentRefreshCompleted':
            return <>Instrument refresh completed</>;
        case 'audioStreamingStarted':
            return <>Audio streaming started</>;
        default:
            return <>{evt.event}</>;
    }
}

function renderFeedback(evt: RecordSessionFeedbackEvent) {
    if (evt.endOfSession) {
        const feedback = evt.feedback as EndOfSessionFeedback;
        return (
            <div className="sessionLogEvents--content">
                Recorded user end-of-session feedback {renderFbUser(evt.fromUser)}: <br />
                Overall experience: {ratingStars(feedback.rating)}
                <br />
                Technical quality (no glitches): {ratingStars(feedback.technicalQualityRating)}
                <br />
                Music quality : {ratingStars(feedback.musicQualityRating)}
                <br />
                Ease of use: {ratingStars(feedback.easeOfUseRating)}
                <br />
                {feedback.feedback && <>"{feedback.feedback}"</>}
            </div>
        );
    } else {
        switch (evt.feedback.type) {
            case 'feedbackLogged':
                return (
                    <>
                        Logged feedback: {renderFbUser(evt.fromUser)} {startCase(evt.feedback.feedbackType)}
                    </>
                );
            case 'feedbackCategorised':
                return (
                    <>
                        Categorised feedback{' '}
                        {evt.feedback.newTags.length > 0 && <>adding {evt.feedback.newTags.join(', ')}</>}
                        {evt.feedback.removedTags.length > 0 && <>removing {evt.feedback.removedTags.join(', ')}</>}
                    </>
                );
            case 'feedbackTextAdded':
                return <>Added text to feedback: "{evt.feedback.text}"</>;
            case 'feedbackCancelled':
                return <>Cancelled feedback</>;
            case 'feedbackConcluded':
                return <>Concluded feedback</>;
            case 'feedbackSubmitted':
                return (
                    <>
                        Recorded user mid-session feedback {renderFbUser(evt.fromUser)}:{' '}
                        {startCase(evt.feedback.feedbackType)}
                        <br />
                        {evt.feedback.text && `Text: "${evt.feedback.text}"`}
                        <br />
                        {!!evt.feedback.tags.length && `Categories: "${evt.feedback.tags.join(', ')}"`}
                    </>
                );
            default:
                const feedback = evt.feedback as LegacyMidSessionFeedback;
                return (
                    <>
                        Recorded user mid-session feedback {renderFbUser(evt.fromUser)}: {feedback.yay ? 'Yay' : 'Nay'}{' '}
                        {feedback.feedback && <>"{feedback.feedback}"</>}
                    </>
                );
        }
    }
}

function ratingStars(rating?: number) {
    if (isNumber(rating)) {
        const invRating = 5 - rating;
        return (
            <>
                {range(rating).map(() => '★')}
                {range(invRating).map(() => '☆')}
            </>
        );
    } else {
        return '-';
    }
}

function renderFbUser(fbUser?: FirebaseUser | AnonymousUser) {
    if (fbUser && fbUser.type === 'firebase') {
        return (
            <>
                {fbUser.name} &lt;{fbUser.email}&gt;
            </>
        );
    } else {
        return <>Anonymous</>;
    }
}

function getStageName(
    evt: EnterStageEvent | EnteredStageEvent | AddStageContentEvent | FailedToFindStageContentEvent,
    score?: SessionScore,
) {
    if (isNumber(evt.sessionStageIndex)) {
        // Legacy logs
        if (!score) {
            return evt.sessionStageIndex;
        }
        const sessionStage = (score as any).session[evt.sessionStageIndex];
        const presetName = evt.stagePreset ? evt.stagePreset.presetName : 'default';
        if (sessionStage.name) {
            return (
                <em>
                    {sessionStage.name} ({sessionStage.preset} {sessionStage.stage} from {presetName})
                </em>
            );
        } else {
            return (
                <em>
                    {sessionStage.preset} {sessionStage.stage} (from {presetName})
                </em>
            );
        }
    } else {
        const { index, pathScore } = evt.sessionStageIndex;
        const sessionPreset = pathScore?.stages[index];
        return (
            <>
                <em>{formatPathName(pathScore)}</em> stage <em>{sessionPreset?.stage}</em>
            </>
        );
    }
}

function getVoiceoverName(evt: ActivateVoiceoverEvent, score: SessionScore) {
    const voStage = score.voiceover![evt.stageIndex];
    return voStage.stage;
}

const Container = styled.div<{ isPastEvent: boolean }>`
    display: grid;
    grid-template-columns: 80px 60px 1fr;
    grid-template-areas: 'time actions content';
    align-items: start;
    opacity: ${({ isPastEvent }) => (isPastEvent ? 0.5 : 1)};
`;

const Time = styled.div<{ rowColour: string }>`
    grid-area: time;
    align-self: stretch;
    border-left: 12px solid ${({ rowColour }) => rowColour};
    padding-left: 8px;
`;

const Actions = styled.div`
    grid-area: actions;
`;

const Content = styled(Typography)`
    grid-area: content;
`;
