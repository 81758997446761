import firebase from 'firebase';
import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SessionHeader, SessionRenderType } from 'wavepaths-shared/core';

import { Dialog } from '@/component-library';

import * as api from '../../common/api/sessionApi';
import { formatDateTime } from '../../dateUtilsV2';
import UserEvents from '../../UserEvents';
import RemoteOptionsDialog from '../inSession/deviceOptions/RemoteOptionsDialog';
import { renderSessionClients, renderSessionName } from './helpers';
import SessionCard, { ISessionAction } from './SessionCard';

// const DOSAGE_LABELS: { [key: number]: string } = {
//     [SessionScoreDosage.LOW]: 'Low',
//     [SessionScoreDosage.MEDIUM]: 'Medium',
//     [SessionScoreDosage.HIGH]: 'High',
// };

interface IProps {
    session: SessionHeader;
    isAdmin: boolean;
    fbUser: firebase.User;
}

// const getSubtitle = (medicine: string, dosage: string | number | undefined): string => {
//     if (medicine === 'undefined' || medicine === 'None') return '';
//     if (dosage && typeof dosage === 'number') return `${medicine} ${DOSAGE_LABELS[dosage]}`;
//     if (dosage) return `${medicine} ${dosage}`;
//     return medicine;
// };

const getSessionCardInfo = (session: SessionHeader) => {
    return session.startedAt ? [`Started ${formatDateTime(session.startedAt)}`] : ['...'];
};

function OngoingSessionCard({ session, isAdmin, fbUser }: IProps): ReactElement {
    const history = useHistory();

    const sessionName = renderSessionName(session);
    const clientNames = renderSessionClients(session);

    const sessionCardInfo = getSessionCardInfo(session);

    const userInfoText = session?.user?.name ?? '';

    // TODO: it can be a string that says "undefined" - not ideal
    // TODO: modality from where?
    // const subtitle = getSubtitle(`${(session.score as any)?.modality}`, session.variableInputs.dosage);

    const [confirmEndOpen, setConfirmEndOpen] = useState(false);

    const handleEndSession = () => {
        setConfirmEndOpen(true);
    };

    const [endSessionAction, setEndSessionAction] = useState<ISessionAction>({
        description: 'End Now',
        action: handleEndSession,
    });

    const onEndSessionConfirmed = () => {
        UserEvents.sessionCompleted(session);

        fbUser && api.deleteSession(session.id, 0, fbUser);
        setConfirmEndOpen(false);
        setEndSessionAction({
            description: 'Ending',
            action: () => {
                // do nothing
            },
        });
    };
    const [showShareSessionDialog, setShowShareSessionDialog] = useState(false);

    const onOpen = () => {
        history.push(`/session/${session.id}`);
    };

    const onOpenPrecomposed = () => {
        history.push(`/session/precomposed/${session.id}`);
    };

    const onClickLog = () => {
        history.push(`/logs/${session.id}`);
    };

    const actions = [
        { description: 'Play on Remote Device', action: () => setShowShareSessionDialog(true) },
        ...(session.renderType !== SessionRenderType.PRE_RENDERED ? [endSessionAction] : []),
        ...(isAdmin ? [{ description: 'Log', action: onClickLog }] : []),
    ] as ISessionAction[];

    /*
        CARD API
        open: onLinkClick
        title: sessionName
        info: formatDateTime(session.startedAt)
        actions: [
            {description: "End Now", action: handleEndSession }
            {description: "Copy Client Link", action: `copies link getSessionLink(session.broadcastIdentifier)`}
            {description: "Log", action: `links to `/logs/${session.id}` `}
        ]
    */

    return (
        <React.Fragment key={session.id}>
            <Dialog
                fullWidth={true}
                open={confirmEndOpen}
                message={`Are you sure you want to end the "${sessionName}" session?`}
                onClose={() => setConfirmEndOpen(false)}
                onConfirm={onEndSessionConfirmed}
                confirmText={'End Session'}
            />
            <RemoteOptionsDialog
                sessionId={session.id}
                broadcastIdentifier={session.broadcastIdentifier}
                isPlayerAppSupported={session.renderType === SessionRenderType.PREDICTIVE_COMPOSED}
                isOpen={showShareSessionDialog}
                onClose={() => setShowShareSessionDialog(false)}
            />
            <SessionCard
                primaryAction={session.renderType === SessionRenderType.PRE_RENDERED ? onOpenPrecomposed : onOpen}
                primaryActionLabel="Open Controls"
                title={sessionName}
                subtitle={clientNames}
                userInfoText={isAdmin ? userInfoText : undefined}
                info={sessionCardInfo}
                actions={actions}
            ></SessionCard>
        </React.Fragment>
    );
}

export default OngoingSessionCard;
