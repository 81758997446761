import { Link } from '@material-ui/core';
import firebase from 'firebase';
import { take } from 'lodash';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import styled from 'styled-components';
import { APISessionStatus, SessionScoreModality } from 'wavepaths-shared/core';

import { Button } from '@/component-library';
import LoadingCardRow from '@/component-library/components/LoadingTemplateCardRow';
import { ScrollRow, ScrollRowItem } from '@/component-library/components/ScrollRow';
import SectionHeading from '@/component-library/components/SectionHeading';
import TemplateCard from '@/component-library/components/TemplateCard';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import { Features } from '@/features';
import { SessionCacheManagerContext } from '@/hooks/useAudioCache';
import useListTemplates from '@/hooks/useListTemplates';

import { useAuthContext } from '../../auth';
import { LayoutContainer } from '../../LayoutContainer';
import NewsLatestContainer from '../news/NewsLatest';
import { WelcomeProductTour } from '../resourcesOld/WelcomeProductTour';
import OfflineSessionCard from '../sessions/EndedSessionsTable/OfflineSessionCard';
import PersonalEndedSessionCard from '../sessions/EndedSessionsTable/PersonalEndedSessionCard';
import TherapistEndedSessionCardV2 from '../sessions/EndedSessionsTable/TherapistEndedSessionCardV2';
import useSessions from '../sessions/useSessions';
import { TemplateContainer } from '../templates/all';
import { Section } from './types';
import { useHomeContent } from './useHomeContent';

type HomeSectionProps = {
    section: Section;
};

const getPathForListResource = (resource: string): string | undefined => {
    switch (resource) {
        case '/my/templates/saved': {
            return '/templates/saved';
        }
        case `/my/home/popular?modality=${SessionScoreModality.KETAMINE}`: {
            return `/templates?modality=${SessionScoreModality.KETAMINE}`;
        }
        case `/my/home/popular?modality=${SessionScoreModality.BREATHWORK}`: {
            return `/templates?modality=${SessionScoreModality.BREATHWORK}`;
        }
        case `/my/home/popular?modality=${SessionScoreModality.MDMA}`: {
            return `/templates?modality=${SessionScoreModality.MDMA}`;
        }
        case `/my/home/popular?modality=${SessionScoreModality.PSILOCYBIN}`: {
            return `/templates?modality=${SessionScoreModality.PSILOCYBIN}`;
        }
    }
};

const getPathBaseForEntityResource = (resource: Section['resourceType']): string | undefined => {
    switch (resource) {
        case 'savedTemplates': {
            return '/templates/saved/';
        }
        case 'templates': {
            return '/templates/';
        }
    }
};

const NoResultsContainer = styled.div`
    width: 100%;
    grid-column: 1/-1;
    text-align: center;
    height: 254px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const CardRowSessionContainer = styled.div`
    width: 200px;
`;

const SectionContent = styled.div`
    min-height: 200px;
`;

const HomeTemplatesSection = React.memo(({ section }: HomeSectionProps) => {
    const { firebaseUser } = useAuthContext();
    const { data, isLoading } = useListTemplates({ fbUser: firebaseUser, resourceUrl: section.resourceUrl });
    const templates = take(data?.entries, 4);
    const history = useHistory();

    const seeAllUrl = getPathForListResource(section.resourceUrl);
    const cardUrl = getPathBaseForEntityResource(section.resourceType);

    return (
        <>
            <SectionHeading
                text={section.title}
                moreLink={seeAllUrl ? { label: 'See All', action: () => history.push(seeAllUrl) } : undefined}
            />
            <ScrollRow>
                {!isLoading ? (
                    templates.length ? (
                        templates.map((t) => (
                            <ScrollRowItem key={'template-' + t.id}>
                                <TemplateCard
                                    id={t.id}
                                    emotionalities={t.emotionalities}
                                    key={'template-' + t.id}
                                    onCardClick={() => {
                                        history.push(cardUrl + t.id);
                                    }}
                                    title={t.name}
                                    subtitle={t.subtitle}
                                    intensity={t.intensity}
                                    modality={t.modality}
                                    minDurationMins={t.durationMins.min}
                                    maxDurationMins={t.durationMins.max}
                                ></TemplateCard>
                            </ScrollRowItem>
                        ))
                    ) : (
                        <ScrollRowItem key={'noresults'}>
                            <NoResultsContainer>
                                <TypographyV2 color="grey-400" size="text-md">
                                    Sorry, there aren't any templates here...
                                </TypographyV2>
                                <Button size="l" onClick={() => history.push('/templates')} variant="clear-underlined">
                                    Explore templates
                                </Button>
                            </NoResultsContainer>
                        </ScrollRowItem>
                    )
                ) : (
                    <LoadingCardRow columns={4} Container={ScrollRowItem} />
                )}
            </ScrollRow>
        </>
    );
});

function Home({ firebaseUser }: { firebaseUser: firebase.User }) {
    const homeContent = useHomeContent({ fbUser: firebaseUser });

    const scheduled = useSessions({
        fbUser: firebaseUser,
        sessionState: APISessionStatus.SCHEDULED,
        paginated: true,
    });

    const ongoing = useSessions({
        fbUser: firebaseUser,
        sessionState: APISessionStatus.ACTIVE,
        paginated: true,
    });

    const endedSessions = useSessions({
        fbUser: firebaseUser,
        sessionState: APISessionStatus.ENDED,
        paginated: true,
    });
    const history = useHistory();
    const [showIntroProductTour, setShowIntroProductTour] = useLocalStorage('ShowIntroProductTour', true);
    const { isPersonal, isEnabled } = useAuthContext();

    const totalSessionCount = scheduled.totalCount + ongoing.totalCount + endedSessions.totalCount;

    const shouldRenderTour =
        !scheduled.loading &&
        !scheduled.error &&
        !ongoing.loading &&
        !ongoing.error &&
        !endedSessions.loading &&
        !endedSessions.error &&
        totalSessionCount === 0 &&
        showIntroProductTour &&
        !isPersonal;

    const sessionCacheManager = useContext(SessionCacheManagerContext);
    if (!sessionCacheManager) throw new Error('No SessionCacheManagerContext');

    return (
        <>
            <SectionHeading text="Quick Start" moreLink={undefined} />
            <SectionContent>
                <TemplateContainer ContainerComponent={ScrollRow} CardComponent={ScrollRowItem} />
            </SectionContent>
            {isEnabled(Features.FREE_ACCESS) ? (
                <></>
            ) : (
                <>
                    {isPersonal ? (
                        <></>
                    ) : (
                        <>
                            <SectionHeading text="Start your subscription" moreLink={undefined} />
                            <SectionContent>
                                <Link href="/subscriptions">
                                    <Button variant="solid-blue">Subscribe</Button>
                                </Link>
                            </SectionContent>
                        </>
                    )}
                </>
            )}
            <NewsLatestContainer />
            {endedSessions.totalCount || ongoing.totalCount ? (
                <>
                    <SectionHeading
                        text="Recently Played Sessions"
                        moreLink={
                            !isPersonal ? { label: 'See All', action: () => history.push('/sessions') } : undefined
                        }
                    />
                    <ScrollRow>
                        <>
                            {ongoing.sessions.map((session, idx) =>
                                isPersonal ? (
                                    <ScrollRowItem key={'session' + idx}>
                                        {
                                            <CardRowSessionContainer>
                                                <PersonalEndedSessionCard
                                                    key={session.id}
                                                    session={session}
                                                    fbUser={firebaseUser}
                                                />
                                            </CardRowSessionContainer>
                                            // TODO for Therapist
                                            // <OngoingSessionCard key={session.id} session={session} fbUser={firebaseUser} isAdmin={false} onLinkClick={() => {}} />
                                        }
                                    </ScrollRowItem>
                                ) : null,
                            )}
                            {endedSessions.sessions.map((session, idx) => (
                                <ScrollRowItem key={'session' + idx}>
                                    {isPersonal ? (
                                        <CardRowSessionContainer>
                                            <PersonalEndedSessionCard
                                                key={session.id}
                                                session={session}
                                                fbUser={firebaseUser}
                                            />
                                        </CardRowSessionContainer>
                                    ) : (
                                        <CardRowSessionContainer>
                                            <TherapistEndedSessionCardV2 key={session.id} session={session} />
                                        </CardRowSessionContainer>
                                    )}
                                </ScrollRowItem>
                            ))}
                        </>
                    </ScrollRow>
                </>
            ) : null}
            {sessionCacheManager.sessionList.length ? (
                <>
                    <SectionHeading text="Offline Sessions" />
                    <ScrollRow>
                        <>
                            {sessionCacheManager.sessionList.map((session) => {
                                return (
                                    <CardRowSessionContainer key={session.id}>
                                        <OfflineSessionCard session={session} fbUser={firebaseUser} />
                                    </CardRowSessionContainer>
                                );
                            })}
                        </>
                    </ScrollRow>
                </>
            ) : (
                <></>
            )}
            {!isPersonal &&
                homeContent?.sections.map((section) => (
                    <HomeTemplatesSection key={section.resourceUrl} section={section} />
                ))}
            {shouldRenderTour && <WelcomeProductTour setShowIntroProductTour={setShowIntroProductTour} />}
        </>
    );
}

function HomeWithNav({ firebaseUser }: { firebaseUser: firebase.User }) {
    return (
        <LayoutContainer>
            <Home firebaseUser={firebaseUser} />
        </LayoutContainer>
    );
}

export default HomeWithNav;
