import styled from '@emotion/styled';
import React, { useCallback, useContext } from 'react';
import QRCode from 'react-qr-code';

import { GlobalSnackbarContext } from '@/components/Snackbar';
import { Features } from '@/features';
import { useSessionLinkForControls } from '@/util/broadcastLinkUtils';

import { useAuthContext } from '../../../../auth';
import CopyIcon from '../../../../images/icons/icon-copy.svg';
import UpgradeMessage from '../UpgradeMessage';

const Container = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

const QRCodeContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

const QRCodeText = styled.p`
    padding: 0;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #475467;
`;

const QRCodeImage = styled(QRCode)`
    width: 150px;
    height: 150px;
`;

const CopyLinkButton = styled.button`
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: 1px solid #475467;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    color: #2b2d3f;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 20px;
    height: 20px;
    stroke: #475467;
`;

function ControlOnRemoteDevice({ sessionId }: { sessionId: string }): React.ReactElement {
    const sessionLinkForControls = useSessionLinkForControls({
        sessionId,
    });

    const { isEnabled } = useAuthContext();
    const { setSnackbarContent } = useContext(GlobalSnackbarContext);

    const copyControlsLink = useCallback(() => {
        navigator.clipboard.writeText(sessionLinkForControls);
        setSnackbarContent('Controls link copied');
    }, [sessionLinkForControls]);

    const blockedToShareWithClient = isEnabled(Features.BLOCKED_REMOTE_LISTEN);

    return (
        <Container>
            {blockedToShareWithClient ? (
                <UpgradeMessage upgradeLink="/profile" />
            ) : (
                <>
                    <QRCodeContent>
                        <QRCodeText>Open controls:</QRCodeText>
                        <QRCodeImage value={sessionLinkForControls} />
                    </QRCodeContent>
                    <CopyLinkButton onClick={copyControlsLink}>
                        Copy link to controls
                        <Icon src={CopyIcon} alt="Copy icon" />
                    </CopyLinkButton>
                </>
            )}
        </Container>
    );
}

export default ControlOnRemoteDevice;
