import React, { useContext, useState } from 'react';

import Dialog from '@/components/Dialog/Dialog';
import DialogFooter from '@/components/Dialog/DialogFooter';
import DialogHeader from '@/components/Dialog/DialogHeader';

import { CancelButton, Column, Columns, FooterActions, SaveButton } from './DeviceOptionsDialogComponents';
import PlaybackOptions from './playbackOptions/PlaybackOptions';
import { PlayerChoiceContext } from './PlayerChoiceContext';

interface PlaybackOptionsDialogProps {
    broadcastIdentifier: string;
    isPlayerAppSupported: boolean;
    isWebPlayerSupported: boolean;
    freePaidPathPartForPlayerAppLink?: string;
}

export default function PlaybackOptionsDialog({
    broadcastIdentifier,
    isPlayerAppSupported,
    isWebPlayerSupported,
    freePaidPathPartForPlayerAppLink,
}: PlaybackOptionsDialogProps): React.ReactElement {
    const playerChoiceContext = useContext(PlayerChoiceContext);
    if (playerChoiceContext === undefined) throw new Error('no PlayerChoiceContext');

    const [whereToPlayMusic, setWhereToPlayMusic] = useState(playerChoiceContext.whereToPlayMusic);
    const [playerChoice, setPlayerChoice] = useState(playerChoiceContext.playerChoice);
    const [browserPlayerOutputDevice, setBrowserPlayerOutputDevice] = useState(
        playerChoiceContext.browserPlayerOutputDevice,
    );

    const mayBeDismissed = whereToPlayMusic !== undefined;
    const haveChanges =
        whereToPlayMusic !== playerChoiceContext.whereToPlayMusic ||
        playerChoice !== playerChoiceContext.playerChoice ||
        browserPlayerOutputDevice !== playerChoiceContext.browserPlayerOutputDevice;

    const onCancel = () => {
        if (mayBeDismissed) {
            playerChoiceContext.closeDialog();
            setWhereToPlayMusic(playerChoiceContext.whereToPlayMusic);
            setPlayerChoice(playerChoiceContext.playerChoice);
            setBrowserPlayerOutputDevice(playerChoiceContext.browserPlayerOutputDevice);
        }
    };
    const onSave = () => {
        if (mayBeDismissed) {
            playerChoiceContext.closeDialogWithUpdate({
                whereToPlayMusic,
                playerChoice,
                browserPlayerOutputDevice,
            });
        }
    };

    return (
        <Dialog isOpen={playerChoiceContext.isDialogOpen}>
            <DialogHeader text={'Playback'} />
            <Columns>
                <Column>
                    <PlaybackOptions
                        broadcastIdentifier={broadcastIdentifier}
                        whereToPlayMusic={whereToPlayMusic}
                        playerChoice={playerChoice}
                        browserPlayerOutputDevice={browserPlayerOutputDevice}
                        isPlayerAppSupported={isPlayerAppSupported}
                        isWebPlayerSupported={isWebPlayerSupported}
                        freePaidPathPartForPlayerAppLink={freePaidPathPartForPlayerAppLink}
                        onSetWhereToPlayMusic={setWhereToPlayMusic}
                        onSetPlayerChoice={setPlayerChoice}
                        onSetBrowserPlayerOutputDevice={setBrowserPlayerOutputDevice}
                    />
                </Column>
            </Columns>
            <DialogFooter>
                <FooterActions>
                    <CancelButton
                        onClick={onCancel}
                        disabled={!mayBeDismissed || !haveChanges || playerChoiceContext.isInitialDialog}
                    >
                        Cancel
                    </CancelButton>
                    <SaveButton onClick={onSave} disabled={!mayBeDismissed}>
                        Save
                    </SaveButton>
                </FooterActions>
            </DialogFooter>
        </Dialog>
    );
}
