import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
`;

const Icon = styled.img`
    display: block;
    width: 24px;
    height: 24px;
`;

const Text = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

const Title = styled.h3`
    font-size: 16px;
    font-weight: 500;
    margin: 0;
`;

const Description = styled.p`
    font-size: 14px;
    color: #475467;
    margin: 0;
`;

interface SubheadingProps {
    title: string;
    description?: string;
    icon?: string;
}

const Subheading: React.FC<SubheadingProps> = ({ title, description, icon }) => {
    return (
        <Container>
            {icon && <Icon src={icon} alt={title + ` icon`} />}
            <Text>
                <Title>{title}</Title>
                {description && <Description>{description}</Description>}
            </Text>
        </Container>
    );
};

export default Subheading;
