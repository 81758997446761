import styled from '@emotion/styled';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { SessionRenderType, SessionState } from 'wavepaths-shared/core';
import { PathType, Wavepath } from 'wavepaths-shared/core';

import { Button } from '@/component-library';

import { Connection, usePendingTimelineChangeState, useSessionRemoteTick } from '../../../common/hooks/useSessionTick';
import { Queueable } from '../actionQueue/useActionQueue';
import StartEndSessionButton from '../autoGuide/StartEndSessionButton';
import { SessionContext } from '../autoGuide/useSession';
import CeaController from '../ceaButtons/CeaController';
import IntensityControllerContainer from '../depthSlider/IntensityController';
import { AudioControlIconWhite, PlayPauseButton } from '../Precomposed/PrecomposedGuide';
import AdjustmentSelectIcon from './AdjustmentSelectIcon';
import NowAndNextCard from './NowAndNextCard';
import { WaveEndCountdownV2 } from './WaveEndCountdownV2';

const PositionedPlayPauseButton = styled(PlayPauseButton)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    @media (min-height: 760px) {
        transform: translate(-50%, -50%) scale(1);
    }
`;

const PositionedStartEndSessionContainer = styled.div({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    whiteSpace: 'nowrap',
});

const AdjustmentsDivider = styled.div({
    height: 1,
    width: '100%',
    backgroundColor: '#2B2D3F',
    opacity: 0.2,
    mixBlendMode: 'color-burn',
});

const Adjustment = styled.div({
    width: '100%',
});

const AdjustmentItem = styled.div({
    height: 48,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '0 8px',
    margin: 0,
    border: 'none',
    textAlign: 'left',
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeight: 600,
    color: '#2B2D3F',
    backgroundColor: 'transparent',
});

const AdjustmentButton = styled.button({
    position: 'relative',
    height: 48,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '0 8px',
    margin: 0,
    border: 'none',
    textAlign: 'left',
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeight: 600,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    transition: 'background-color 0.15s',
    '&:hover': {
        backgroundColor: 'rgba(43, 45, 63, 0.05)',
    },
    '&:disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
});

const AdjustmentLabel = styled.div({
    color: '#2B2D3F',
});

const AdjustmentIcon = styled.svg({
    opacity: 0.5,
    mixBlendMode: 'color-burn',
});

export default function CurrentWaveCard({
    initialised,
    started,
    paused,
    onStartSession,
    onEndSession,
    pause,
    resume,
    connection,
    currentWave,
    currentDepth,
    queueFunction,
}: {
    initialised: boolean;
    started: boolean;
    paused: boolean;
    onStartSession: () => void;
    onEndSession: () => void;
    pause: () => void;
    resume: () => void;
    connection: Connection;
    currentWave: Wavepath | null;
    currentDepth: number | null;
    queueFunction: (queueable: Queueable) => void;
}): React.ReactElement {
    const isLoading = !initialised;

    const tick = useSessionRemoteTick();

    const isPrelude = tick?.sessionState === SessionState.PRELUDE;
    const isPostlude = tick?.sessionState === SessionState.POSTLUDE;

    const waveDuration =
        currentWave?.type === 'scheduled' && currentWave?.plan
            ? currentWave.plan.toTime - currentWave.plan.fromTime
            : undefined;
    const timeUntilEnd =
        currentWave?.type === 'scheduled' && tick && currentWave.plan
            ? currentWave.plan.toTime - tick.effectiveTime
            : undefined;
    const waveProgress = timeUntilEnd && waveDuration ? (waveDuration - timeUntilEnd) / waveDuration : 0;

    const [ceaOpen, setCeaOpen] = useState<boolean>(false);
    const [intensityControlOpen, setIntensityControlOpen] = useState<boolean>(false);

    const session = useContext(SessionContext);
    if (session === undefined) throw new Error('missing session context');

    const queueFunctionAndCloseIntensityDialog = useCallback((...args: Parameters<typeof queueFunction>) => {
        setIntensityControlOpen(false);
        queueFunction(...args);
    }, []);

    const showCountdown = !isLoading && !isPrelude && !isPostlude && currentWave;
    const shouldExtensionBeDisabled = !currentWave || currentWave.pathScore.type !== PathType.GENERATIVE;

    const { hasPendingChange, timeUntilChange } = usePendingTimelineChangeState();

    const initialTimeUntilChange = useRef<number | null>(null);

    useEffect(() => {
        if (hasPendingChange && initialTimeUntilChange.current === null) {
            initialTimeUntilChange.current = timeUntilChange;
        }
    }, [hasPendingChange, timeUntilChange]);

    const percentage = initialTimeUntilChange.current ? (timeUntilChange / initialTimeUntilChange.current) * 100 : 0;

    const togglePlaying = async () => {
        if (!paused) {
            pause();
            //audioPlayer.actions.pause({ reason: 'user' });
        } else {
            resume();
            //await audioPlayer.actions.unblock();
            //audioPlayer.actions.play();
        }
    };

    return (
        <NowAndNextCard
            initialised={initialised}
            wave={currentWave}
            progress={waveProgress}
            heading={<></>}
            body={
                <>
                    {started && currentWave && session.renderType === SessionRenderType.PREDICTIVE_COMPOSED && (
                        <PositionedPlayPauseButton onClick={togglePlaying}>
                            {!paused && (
                                <AudioControlIconWhite
                                    width="48"
                                    height="48"
                                    viewBox="0 0 48 48"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M28.25 38V10H36V38H28.25ZM12 38V10H19.75V38H12Z" />
                                </AudioControlIconWhite>
                            )}
                            {paused && (
                                <AudioControlIconWhite
                                    width="48"
                                    height="48"
                                    viewBox="0 0 48 48"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M14 38V10L36 24L14 38Z" />
                                </AudioControlIconWhite>
                            )}
                        </PositionedPlayPauseButton>
                    )}
                    <PositionedStartEndSessionContainer>
                        <StartEndSessionButton
                            onStartSession={onStartSession}
                            onEndSession={onEndSession}
                            broadcastIdentifier={session.broadcastIdentifier}
                        />
                    </PositionedStartEndSessionContainer>
                    {/*
                      Is this ever visible at the moment?
                      <InstrumentRefreshButton instrumentRefreshArgs={instrumentRefreshArgs} />
                    */}
                </>
            }
            adjustments={
                currentWave ? (
                    <>
                        <Adjustment>
                            {currentWave && showCountdown && (
                                <WaveEndCountdownV2
                                    wave={currentWave}
                                    connection={connection}
                                    shouldExtensionBeDisabled={shouldExtensionBeDisabled}
                                    hasPendingChange={hasPendingChange}
                                    percentage={percentage}
                                />
                            )}
                        </Adjustment>
                        <AdjustmentsDivider />
                        <Adjustment>
                            <AdjustmentButton
                                onClick={() => setCeaOpen(true)}
                                disabled={hasPendingChange}
                                className={'tour-ceaController'}
                            >
                                <AdjustmentIcon
                                    width="15"
                                    height="16"
                                    viewBox="0 0 15 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="7.5" cy="8" r="7.5" fill="#2B2D3F" />
                                </AdjustmentIcon>
                                <AdjustmentLabel>{currentWave?.pathScore.name ?? 'Emotionality'}</AdjustmentLabel>
                                <AdjustmentSelectIcon hasPendingChange={hasPendingChange} percentage={percentage} />
                            </AdjustmentButton>
                        </Adjustment>
                        {ceaOpen && (
                            <Dialog open={ceaOpen} onClose={() => setCeaOpen(false)} maxWidth={'xs'}>
                                <DialogTitle>Change emotionality</DialogTitle>
                                <DialogContent
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: '15px',
                                    }}
                                >
                                    <CeaController onClose={() => setCeaOpen(false)} />
                                    <div>
                                        <Button variant="clear-underlined" onClick={() => setCeaOpen(false)}>
                                            Close
                                        </Button>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        )}
                        <AdjustmentsDivider />
                        <Adjustment>
                            <AdjustmentButton
                                onClick={() => setIntensityControlOpen(true)}
                                disabled={hasPendingChange}
                                className={'tour-intensityController'}
                            >
                                <AdjustmentIcon
                                    width="15"
                                    height="16"
                                    viewBox="0 0 15 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect y="0.5" width="15" height="15" fill="url(#lineGrad)" />
                                    <defs>
                                        <linearGradient
                                            id="lineGrad"
                                            x1="7.5"
                                            y1="0.5"
                                            x2="7.5"
                                            y2="15.5"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#2B2D3F" />
                                            <stop offset="1" stopColor="#2B2D3F" stopOpacity="0" />
                                        </linearGradient>
                                    </defs>
                                </AdjustmentIcon>
                                <AdjustmentLabel>Intensity {currentDepth ?? ''}</AdjustmentLabel>
                                <AdjustmentSelectIcon hasPendingChange={hasPendingChange} percentage={percentage} />
                            </AdjustmentButton>
                        </Adjustment>
                        {intensityControlOpen && (
                            <Dialog
                                open={intensityControlOpen}
                                onClose={() => setIntensityControlOpen(false)}
                                maxWidth={'md'}
                            >
                                <DialogTitle>Change intensity</DialogTitle>
                                <DialogContent
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: '15px',
                                    }}
                                >
                                    <IntensityControllerContainer
                                        opacity={initialised ? 1 : 0}
                                        connection={connection}
                                        queueFunction={queueFunctionAndCloseIntensityDialog}
                                        sliderVisible={!isPrelude && !isPostlude}
                                    />

                                    <div>
                                        <Button
                                            variant="clear-underlined"
                                            onClick={() => setIntensityControlOpen(false)}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        )}
                    </>
                ) : isPrelude || isPostlude ? (
                    <>
                        <Adjustment>
                            <AdjustmentItem>
                                <AdjustmentIcon
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.55 0H3.45C3.02996 0 2.81994 0 2.65951 0.0817451C2.51839 0.15365 2.40365 0.268386 2.33175 0.409507C2.25 0.569941 2.25 0.779961 2.25 1.2V2.75589C2.25 3.12277 2.25 3.30622 2.29145 3.47885C2.32819 3.6319 2.3888 3.77822 2.47104 3.91243C2.5638 4.0638 2.69352 4.19352 2.95294 4.45294L5.15147 6.65147C5.44848 6.94848 5.59699 7.09699 5.65263 7.26824C5.70158 7.41887 5.70158 7.58113 5.65263 7.73176C5.59699 7.90301 5.44848 8.05151 5.15147 8.34853L2.95294 10.5471C2.69352 10.8065 2.5638 10.9362 2.47104 11.0876C2.3888 11.2218 2.32819 11.3681 2.29145 11.5212C2.25 11.6938 2.25 11.8772 2.25 12.2441V13.8C2.25 14.22 2.25 14.4301 2.33175 14.5905C2.40365 14.7316 2.51839 14.8463 2.65951 14.9183C2.81994 15 3.02996 15 3.45 15H11.55C11.97 15 12.1801 15 12.3405 14.9183C12.4816 14.8463 12.5963 14.7316 12.6683 14.5905C12.75 14.4301 12.75 14.22 12.75 13.8V12.2441C12.75 11.8772 12.75 11.6938 12.7086 11.5212C12.6718 11.3681 12.6112 11.2218 12.529 11.0876C12.4362 10.9362 12.3065 10.8065 12.0471 10.5471L9.84853 8.34853C9.55151 8.05151 9.40301 7.90301 9.34737 7.73176C9.29842 7.58113 9.29842 7.41887 9.34737 7.26824C9.40301 7.09699 9.55151 6.94848 9.84853 6.65147L12.0471 4.45294C12.3065 4.19352 12.4362 4.0638 12.529 3.91243C12.6112 3.77822 12.6718 3.6319 12.7086 3.47885C12.75 3.30622 12.75 3.12277 12.75 2.75589V1.2C12.75 0.779961 12.75 0.569941 12.6683 0.409507C12.5963 0.268386 12.4816 0.15365 12.3405 0.0817451C12.1801 0 11.97 0 11.55 0Z"
                                        fill="url(#timerGrad)"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="timerGrad"
                                            x1="7.5"
                                            y1="0"
                                            x2="7.5"
                                            y2="15"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#2B2D3F" stopOpacity="0.2" />
                                            <stop offset="1" stopColor="#2B2D3F" />
                                        </linearGradient>
                                    </defs>
                                </AdjustmentIcon>
                                <AdjustmentLabel>
                                    <svg
                                        width="20"
                                        height="21"
                                        viewBox="0 0 20 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.1481 7.16669C19.3948 7.16669 19.3948 13.8334 15.1481 13.8334C10.9023 13.8334 9.20392 7.16669 4.53225 7.16669C0.711418 7.16669 0.711418 13.8334 4.53225 13.8334C9.20392 13.8334 10.9023 7.16669 15.1489 7.16669H15.1481Z"
                                            stroke="#2B2D3F"
                                            strokeOpacity="0.8"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </AdjustmentLabel>
                            </AdjustmentItem>
                        </Adjustment>
                        <AdjustmentsDivider />
                        <Adjustment>
                            <AdjustmentItem>
                                <AdjustmentIcon
                                    width="15"
                                    height="16"
                                    viewBox="0 0 15 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="7.5" cy="8" r="7.5" fill="#2B2D3F" />
                                </AdjustmentIcon>
                                <AdjustmentLabel>Stillness</AdjustmentLabel>
                            </AdjustmentItem>
                        </Adjustment>
                        <AdjustmentsDivider />
                        <Adjustment>
                            <AdjustmentItem>
                                <AdjustmentIcon
                                    width="15"
                                    height="16"
                                    viewBox="0 0 15 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect y="0.5" width="15" height="15" fill="url(#lineGrad)" />
                                    <defs>
                                        <linearGradient
                                            id="lineGrad"
                                            x1="7.5"
                                            y1="0.5"
                                            x2="7.5"
                                            y2="15.5"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#2B2D3F" />
                                            <stop offset="1" stopColor="#2B2D3F" stopOpacity="0" />
                                        </linearGradient>
                                    </defs>
                                </AdjustmentIcon>
                                <AdjustmentLabel>Intensity 1</AdjustmentLabel>
                            </AdjustmentItem>
                        </Adjustment>
                    </>
                ) : (
                    <></>
                )
            }
        />
    );
}
