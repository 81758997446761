import styled from '@emotion/styled';
import React from 'react';

import AppStoreLogo from '../../../../images/download-on-the-app-store.svg';

const Container = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 0 16px;
`;

const Text = styled.p`
    padding: 0;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #475467;
`;

const Br = styled.br`
    display: none;
    @media (min-width: 992px) {
        display: block;
    }
`;

const Link = styled.a`
    display: block;
    width: 120px;
    height: 40px;
    svg {
        display: block;
        width: 100%;
        height: 100%;
    }
`;

export default function AppStoreContent({ appStoreLink }: { appStoreLink: string }): React.ReactElement {
    return (
        <Container>
            <Text>
                Download the app
                <Br /> for Mac or iOS:
            </Text>
            <Link href={appStoreLink} target="_blank">
                <img src={AppStoreLogo} alt="Apple App Store" />
            </Link>
        </Container>
    );
}
