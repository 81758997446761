import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import ProgressCircle from './ProgressCircle';

const AdjustmentLock = styled(motion.div)({
    position: 'absolute',
    right: 8,
    width: '24px',
    height: '24px',
});

const AdjustmentLockProgress = styled.div({
    position: 'absolute',
    top: -1,
    right: -2,
    width: '28px',
    height: '28px',
    opacity: 0.25,
    mixBlendMode: 'color-burn',
});

const AdjustmentLockIcon = styled.svg({
    opacity: 0.5,
    mixBlendMode: 'color-burn',
});

const AdjustmentEdit = styled(motion.div)({
    position: 'absolute',
    right: 8,
});

const AdjustmentEditIcon = styled.svg({
    opacity: 0.5,
    mixBlendMode: 'color-burn',
});

type AdjustmentSelectIconProps = {
    hasPendingChange: boolean;
    percentage: number;
};

const AdjustmentSelectIcon: React.FC<AdjustmentSelectIconProps> = ({
    hasPendingChange,
    percentage,
}: {
    hasPendingChange: boolean;
    percentage: number;
}) => (
    <AnimatePresence>
        {hasPendingChange ? (
            <AdjustmentLock key="lock" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <AdjustmentLockIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7 13.875C7 12.8249 7 12.2999 7.20436 11.8988C7.38413 11.546 7.67096 11.2591 8.02377 11.0794C8.42485 10.875 8.9499 10.875 10 10.875H14C15.0501 10.875 15.5751 10.875 15.9762 11.0794C16.329 11.2591 16.6159 11.546 16.7956 11.8988C17 12.2999 17 12.8249 17 13.875V14.75C17 15.8001 17 16.3251 16.7956 16.7262C16.6159 17.079 16.329 17.3659 15.9762 17.5456C15.5751 17.75 15.0501 17.75 14 17.75H10C8.9499 17.75 8.42485 17.75 8.02377 17.5456C7.67096 17.3659 7.38413 17.079 7.20436 16.7262C7 16.3251 7 15.8001 7 14.75V13.875Z"
                        fill="#2B2D3F"
                    />
                    <path
                        d="M15.125 10.875V9.625C15.125 7.89911 13.7259 6.5 12 6.5C10.2741 6.5 8.875 7.89911 8.875 9.625V10.875M12 13.6875V14.9375M10 17.75H14C15.0501 17.75 15.5751 17.75 15.9762 17.5456C16.329 17.3659 16.6159 17.079 16.7956 16.7262C17 16.3251 17 15.8001 17 14.75V13.875C17 12.8249 17 12.2999 16.7956 11.8988C16.6159 11.546 16.329 11.2591 15.9762 11.0794C15.5751 10.875 15.0501 10.875 14 10.875H10C8.9499 10.875 8.42485 10.875 8.02377 11.0794C7.67096 11.2591 7.38413 11.546 7.20436 11.8988C7 12.2999 7 12.8249 7 13.875V14.75C7 15.8001 7 16.3251 7.20436 16.7262C7.38413 17.079 7.67096 17.3659 8.02377 17.5456C8.42485 17.75 8.9499 17.75 10 17.75Z"
                        stroke="#2B2D3F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </AdjustmentLockIcon>
                <AdjustmentLockProgress>
                    <ProgressCircle percentage={percentage} width={28} />
                </AdjustmentLockProgress>
            </AdjustmentLock>
        ) : (
            <AdjustmentEdit key="edit" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <AdjustmentEditIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6 9L12 15L18 9"
                        stroke="#2B2D3F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </AdjustmentEditIcon>
            </AdjustmentEdit>
        )}
    </AnimatePresence>
);

export default AdjustmentSelectIcon;
