import { millisecondsToSeconds } from 'date-fns';
import { isEqual, pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import {
    PredictiveSessionTick,
    RequestType,
    SessionRequestProperties,
    SessionState,
    SessionTick,
    TickMusicalContent,
    Wavepath,
} from 'wavepaths-shared/core';
import { getCurrentWaveIndex } from 'wavepaths-shared/domain/session';
import { create } from 'zustand';

import { Queueable } from '../../pages/inSession/actionQueue/useActionQueue';

type TickStore = {
    tick?: SessionTick;
    setTick: (tick: SessionTick | undefined) => void;
};
export const useTickStore = create<TickStore>((set) => ({
    tick: undefined,
    setTick: (tick: SessionTick | undefined) => set({ tick }),
}));

type CurrentMusicalContentStore = {
    musicalContent?: TickMusicalContent;
    setMusicalContent: (tick: TickMusicalContent | undefined) => void;
};
export const useCurrentMusicalContentStore = create<CurrentMusicalContentStore>((set, get) => ({
    musicalContent: undefined,
    setMusicalContent: (musicalContent: TickMusicalContent | undefined) => {
        !isEqual(get().musicalContent, musicalContent) && set({ musicalContent });
    },
}));

export function useSessionRemoteTick(): SessionTick | undefined {
    const { tick } = useTickStore();
    return tick;
}

export function useRemoteElapsedTimeSecs(): number {
    const tick = useSessionRemoteTick();

    const elapsedTime = useMemo(() => {
        return tick?.absoluteTime ? Math.floor(millisecondsToSeconds(tick.absoluteTime)) : 0;
    }, [tick?.absoluteTime]);
    return elapsedTime;
}

type SessionDurations = { sessionDuration: number };
export function useRemoteSessionDurations(zeroTick?: SessionTick): SessionDurations {
    const { tick } = useTickStore();
    const defaultDurations = { sessionDuration: -1 };
    return pick(tick ?? zeroTick ?? defaultDurations, 'sessionDuration');
}

type RemoteSessionState = {
    initialised: boolean;
    started: boolean;
    inPostlude: boolean;
    ended: boolean;
    paused: boolean;
};

export function useRemoteSessionState(zeroTick?: SessionTick): RemoteSessionState {
    const { tick } = useTickStore();
    const effectiveTick = tick ?? zeroTick;
    const state = useMemo(
        () =>
            effectiveTick
                ? {
                      initialised: effectiveTick.timeSinceInit >= 0,
                      started: effectiveTick.absoluteTime > 0,
                      inPostlude:
                          effectiveTick.sessionState === SessionState.ENDED ||
                          effectiveTick.sessionState === SessionState.POSTLUDE,
                      ended: effectiveTick.sessionState === SessionState.ENDED,
                      paused: effectiveTick.sessionState === SessionState.PAUSE,
                  }
                : {
                      initialised: false,
                      started: false,
                      inPostlude: false,
                      ended: false,
                      paused: false,
                  },
        [effectiveTick],
    );

    return state;
}

export interface Connection {
    on: (event: string | symbol, listener: (...args: any[]) => void) => void;
    off: (event: string | symbol, listener: (...args: any[]) => void) => void;
    sendRequest: (reqProps: SessionRequestProperties) => void;
    audioAnalyserLeft?: AnalyserNode | null;
    audioAnalyserRight?: AnalyserNode | null;
}

type CurrentWave = { wave: Wavepath | null; index: number };
export function useRemoteCurrentWave(wavepaths: Wavepath[] | null): CurrentWave {
    const tick = useTickStore(({ tick }) =>
        tick
            ? {
                  effectiveTime: tick?.effectiveTime,
                  sessionDuration: tick?.sessionDuration,
              }
            : undefined,
    );

    if (!wavepaths || !tick) return { wave: null, index: -1 };
    const idx = getCurrentWaveIndex(wavepaths, tick);
    if (idx === -1) return { wave: null, index: -1 };
    return { index: idx, wave: wavepaths[idx] };
}

export function useRemoteSessionPlaybackControl(connection: Connection, queueFunction: (queueable: Queueable) => void) {
    // const { connection } = useConnectionStore();
    const pause = useCallback(() => {
        queueFunction({
            description: `Pausing playback on all devices`,
            callback: () => {
                connection.sendRequest({ type: RequestType.Pause });
            },
        });
    }, [connection, queueFunction]);

    const resume = useCallback(() => {
        queueFunction({
            description: `Resume playback on all devices`,
            callback: () => {
                connection.sendRequest({ type: RequestType.Resume });
            },
        });
    }, [connection, queueFunction]);

    return {
        pause,
        resume,
    };
}

export const usePendingTimelineChangeState = () => {
    const tick = useSessionRemoteTick() as PredictiveSessionTick | SessionTick | undefined;
    if (tick && 'pendingChangeState' in tick) {
        return tick.pendingChangeState;
    }
    return { hasPendingChange: false, timeUntilChange: -1 };
};
