import React, { useRef, useState } from 'react';

export type StateWithRef<T> = readonly [React.MutableRefObject<T>, (x: T) => void, T];

export const useStateWithRef = <T>(initialValue: T): StateWithRef<T> => {
    const [state, _setState] = useState(initialValue);
    const stateRef = useRef(initialValue);

    const setState = (x: T) => {
        stateRef.current = x;
        _setState(x);
    };

    return [stateRef, setState, state] as const;
};
