import React, { useContext, useState } from 'react';
import { MidSessionFeedbackType, SessionFeedback, Timestamped } from 'wavepaths-shared/core';

import { useSessionRemoteTick } from '@/hooks';

import { useAuthContext } from '../../../auth';
import { SessionContext } from '../../../pages/inSession/autoGuide/useSession';
import { OnlineContext } from '../../../pages/inSession/useIsOnline';
import { submitInSessionFeedback } from '../../api/sessionApi';
import { FeedbackButton } from './FeedbackButton';
import { NotificationStack, TimestampedFeedbackEventType } from './NotificationStack';

interface FeedbackButtonsProps {
    className?: string;
}
export const FeedbackButtons: React.FC<FeedbackButtonsProps> = ({ className }) => {
    const isOnline = useContext(OnlineContext);
    const [feedback, setFeedback] = useState<TimestampedFeedbackEventType | undefined>(undefined);
    const activeFeedbackType = feedback?.type;
    const { firebaseUser } = useAuthContext();
    const session = useContext(SessionContext);
    const tick = useSessionRemoteTick();

    const onRecordFeedback = (feedback: Timestamped<SessionFeedback>) => {
        if (!firebaseUser) {
            throw new Error('No user');
        }
        if (!session) {
            throw new Error('No session');
        }
        submitInSessionFeedback(session.id, feedback, feedback.timestamp, firebaseUser);
    };

    //TODO: use DSP TIME or server time???? Not Browser time!!!!
    const onLogFeedback = (type: MidSessionFeedbackType) => {
        const timeElapsedMs = tick ? tick.absoluteTime : 0;
        setFeedback({ type, timestamp: Date.now(), timeElapsedMs });
    };
    //TODO: support these buttons when offline
    return (
        <div className={className}>
            {firebaseUser ? (
                <>
                    <FeedbackButton
                        ariaLabel="Session Note"
                        icon="note"
                        onClick={() => onLogFeedback('note')}
                        isActive={activeFeedbackType === 'note'}
                        isDisabled={!!activeFeedbackType || !isOnline}
                    />
                    <FeedbackButton
                        ariaLabel="Helpful Music"
                        icon="helpfulMusic"
                        onClick={() => onLogFeedback('helpfulMusic')}
                        isActive={activeFeedbackType === 'helpfulMusic'}
                        isDisabled={!!activeFeedbackType || !isOnline}
                    />
                    <FeedbackButton
                        ariaLabel="Unhelpful Music"
                        icon="unhelpfulMusic"
                        onClick={() => onLogFeedback('unhelpfulMusic')}
                        isActive={activeFeedbackType === 'unhelpfulMusic'}
                        isDisabled={!!activeFeedbackType || !isOnline}
                    />
                    <FeedbackButton
                        classNames="tour-feedback"
                        ariaLabel="Report Issue"
                        icon="reportIssue"
                        onClick={() => onLogFeedback('issue')}
                        isActive={activeFeedbackType === 'issue'}
                        isDisabled={!!activeFeedbackType || !isOnline}
                    />
                </>
            ) : (
                <></>
            )}
            <NotificationStack
                feedback={feedback}
                onCancel={() => setFeedback(undefined)}
                onSubmitFeedback={(fb) => {
                    onRecordFeedback(fb);
                    setFeedback(undefined);
                }}
            />
        </div>
    );
};
