import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #d0d5dd;
    background: #fff;

    padding: 16px;

    @media (min-width: 768px) {
        padding: 32px;
    }

    @media (min-width: 992px) {
        padding: 40px;
    }
`;

interface DialogFooterProps {
    children: React.ReactNode;
}

const DialogFooter: React.FC<DialogFooterProps> = ({ children }) => {
    return <Container>{children}</Container>;
};

export default DialogFooter;
