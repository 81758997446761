import React from 'react';

type ProgressCircleProps = {
    percentage: number;
    width: number;
    strokeWidth?: number;
    color?: string;
};

const ProgressCircle: React.FC<ProgressCircleProps> = ({ percentage, width, color = '#2B2D3F', strokeWidth = 2 }) => {
    const diameter = width - strokeWidth * 2;
    const radius = diameter / 2;
    const circumference = 2 * Math.PI * radius;

    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    return (
        <svg width={width} height={width} viewBox={`0 0 ${width} ${width}`}>
            <circle
                cx={radius + strokeWidth}
                cy={radius + strokeWidth}
                r={radius}
                fill="none"
                stroke={color}
                strokeWidth={strokeWidth}
                strokeDasharray={circumference}
                strokeDashoffset={strokeDashoffset}
                style={{
                    transition: 'stroke-dashoffset 0.5s ease',
                    transform: 'rotate(-90deg)',
                    transformOrigin: '50% 50%',
                }}
            />
        </svg>
    );
};

export default ProgressCircle;
