import firebase from 'firebase/app';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

import { useAuthContext } from '../../auth';
import {
    archiveResource,
    createResource,
    getResources,
    ResourceData,
    unarchiveResource,
    updateResource,
} from '../../common/api/resourcesApi';

export type Resource = {
    id: string;
    tag?: string;
    sectionId?: string;
    shortDescription?: string;
    description?: string;
    date?: Date;
    isArchived?: boolean;
};

// TODO: refactor it, move to separate file
export type UseAsyncMutation<P extends any[]> = (
    props?: UseAsyncMutationProps,
) => {
    isLoading: boolean;
    error: unknown | null;
    mutate: (...params: P) => Promise<void>;
};

export type UseAsyncMutationProps = {
    onSuccess?: () => void;
    onFailure?: (error: unknown) => void;
    onFinally?: () => void;
    onLoading?: () => void;
};

export const createUseAsyncMutation = <P extends any[], R>(
    fn: (firebaseUser: firebase.User, ...params: P) => Promise<R>,
): UseAsyncMutation<P> => (props) => {
    const { onLoading, onSuccess, onFailure, onFinally } = props || {};
    const { firebaseUser } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<unknown | null>(null);

    const mutate = async (...params: P) => {
        if (!firebaseUser) {
            throw new Error('User not logged in');
        }
        onLoading?.();
        setIsLoading(true);
        setError(null);
        try {
            await fn(firebaseUser, ...params);
            onSuccess?.();
        } catch (e: any) {
            setError(e);
            onFailure?.(e);
        } finally {
            setIsLoading(false);
            onFinally?.();
        }
    };

    return { isLoading, error, mutate };
};

export type UseResourcesProps = {
    includeArchived?: boolean;
};

export const useResources = (props?: UseResourcesProps) => {
    const { includeArchived } = props || {};
    const { firebaseUser } = useAuthContext();
    const { data, isValidating, mutate } = useSWR(
        ['resources', includeArchived],
        async ([, includeArchived]) => {
            if (!firebaseUser) {
                return [];
            }
            return getResources(firebaseUser, {
                includeArchived,
            });
        },
        {
            compare: (a, b) => JSON.stringify(a) === JSON.stringify(b),
            revalidateOnFocus: false,
        },
    );

    const refresh = async () => {
        await mutate();
    };

    const resources: Resource[] = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.map(mapResourceToModel);
    }, [data]);

    return { resources, isLoading: isValidating, refresh };
};

export const useResourcesCreate = createUseAsyncMutation(createResource);
export const useResourcesUpdate = createUseAsyncMutation(updateResource);
export const useResourcesArchive = createUseAsyncMutation(archiveResource);
export const useResourcesUnarchive = createUseAsyncMutation(unarchiveResource);

const mapResourceToModel = (article: ResourceData): Resource => ({
    id: article.id,
    tag: article.tag,
    sectionId: article.sectionId,
    shortDescription: article.shortDescription,
    description: article.description,
    date: article.publishedAt ? new Date(article.publishedAt) : undefined,
    isArchived: !!article.archivedAt,
});
