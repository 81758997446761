import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 16px;
`;

const Line = styled.div`
    width: 100%;
    height: 2px;
    background: #d9d9d9;
`;

const Text = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 0 16px;
    color: #475467;
`;

export default function TextDivider({ text }: { text?: string }): React.ReactElement {
    return (
        <Container>
            <Line />
            {text && <Text>{text}</Text>}
        </Container>
    );
}
