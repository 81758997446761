import styled from '@emotion/styled';
import React from 'react';

import { EvaIcon } from '@/component-library';
import TypographyV2 from '@/component-library/typography/TypographyV2';

import { SupportPostRichEditor } from './SupportPostRichEditor';
import { SupportPost } from './useSupportPosts';

const Container = styled.div(({}) => ({
    backgroundColor: 'white',
    overflow: 'hidden',
    padding: '0 32px',
    opacity: '100%',
    position: 'relative',
    boxShadow: '0px 0px 10px #CFD6E7',
    border: '1px solid white',
    borderRadius: '8px',
    color: 'rgb(44, 57, 88)',
    cursor: 'pointer',
    marginBottom: 20,
}));

const Title = styled(TypographyV2)({
    marginBottom: 8,
});

const TitleContainer = styled.div<{ isExpanded: boolean }>(({ isExpanded }) => ({
    paddingTop: 12,
    paddingBottom: 12,
    marginBottom: isExpanded ? 12 : undefined,
    borderBottom: isExpanded ? '1px solid rgba(17, 17, 17, 0.1)' : undefined,
}));
const Content = styled(TypographyV2)({
    marginBottom: 16,
});
const ExpandIconContainer = styled.div<{ isExpanded: boolean }>`
    position: absolute;
    left: 6px;
    top: 17px;
`;

type SupportPostProps = React.PropsWithChildren<{
    post: SupportPost;
    isExpanded?: boolean;
    onClick?: () => void;
}>;

export function SupportPost({ post, isExpanded = false, onClick, children }: SupportPostProps) {
    return (
        <Container>
            <div onClick={onClick}>
                <ExpandIconContainer
                    style={{ transform: isExpanded ? 'rotate(90deg)' : 'rotate(0)' }}
                    isExpanded={isExpanded}
                >
                    <EvaIcon name="chevron-right-outline" fill="#475467" size={24} />
                </ExpandIconContainer>
                <TitleContainer isExpanded={isExpanded}>
                    <Title element={'h4'} font={'tenor-sans'} weight={'regular'} size={'display-xs'} color={'grey-700'}>
                        <>
                            {post.tag}: {post.shortDescription}
                        </>
                    </Title>
                </TitleContainer>
            </div>
            {isExpanded && post.description && (
                <>
                    <Content color="grey-600" size="text-sm" element="div">
                        <SupportPostRichEditor value={post.description} isEditable={false} />
                    </Content>
                </>
            )}
            {children}
        </Container>
    );
}
