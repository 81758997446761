import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

const Backdrop = styled(motion.div)`
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: start;
    backdrop-filter: blur(5px);

    justify-content: center;
    padding: 0;

    @media (min-width: 768px) {
        align-items: center;
        padding: 12px;
    }
    @media (min-width: 992px) {
        padding: 16px;
    }
`;

const Window = styled(motion.div)`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 0px;
    padding: 16px;

    @media (min-width: 768px) {
        border-radius: 24px;
        padding: 32px;
    }
    @media (min-width: 992px) {
        padding: 40px;
        max-width: 820px;
        max-height: 1145px;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    top: 40px;
    right: 40px;
    display: inline-flex;
    align-items: center;
    padding: 0 16px;
    background: transparent;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    font-size: 16px;
    color: black;
    cursor: pointer;
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    padding-bottom: 154px; // Accommodate for footer
    @media (min-width: 768px) {
        padding-bottom: 124px;
    }
`;

interface DialogProps {
    isOpen: boolean;
    onClose?: () => void;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    children: React.ReactNode;
    showCloseButton?: boolean;
    closeOnBackdropClick?: boolean;
}

const Dialog: React.FC<DialogProps> = ({
    isOpen,
    onClose,
    children,
    showCloseButton = false,
    closeOnBackdropClick = false,
}) => {
    return (
        <AnimatePresence>
            {isOpen && (
                <Backdrop
                    key="dialog-backdrop"
                    onClick={closeOnBackdropClick ? onClose : undefined}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.25 }}
                >
                    <Window
                        onClick={(e: React.MouseEvent) => e.stopPropagation()}
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.95 }}
                        transition={{ duration: 0.25, delay: 0.15 }}
                    >
                        <Content>
                            {showCloseButton && <CloseButton onClick={onClose}>Close</CloseButton>}
                            {children}
                        </Content>
                    </Window>
                </Backdrop>
            )}
        </AnimatePresence>
    );
};

export default Dialog;
