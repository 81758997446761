import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import Subheading from '@/components/Subheading';

import RadioCheckedIcon from '../../../../images/icons/icon-radio-checked.svg';
import RadioUncheckedIcon from '../../../../images/icons/icon-radio-unchecked.svg';
import SlidersIcon from '../../../../images/icons/icon-sliders.svg';
import { WhereToControl } from '../PlayerChoiceContext';
import ControlOnRemoteDevice from './ControlOnRemoteDevice';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const RadioGroup = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Radio = styled.div<{ isFirstChild?: boolean; isLastChild?: boolean; isChecked: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: ${({ isChecked }) => (isChecked ? 'inset 0 0 0 2px #103da8' : 'inset 0 0 0 1px #EAECF0')};
    border-radius: ${({ isFirstChild, isLastChild }) =>
        isFirstChild ? '12px 12px 0 0' : isLastChild ? '0 0 12px 12px' : '0'};
`;

const RadioHeader = styled.div<{ isFirstChild?: boolean; isLastChild?: boolean; isChecked: boolean }>`
    padding: 16px 20px 16px 16px;
    font-size: 16px;
    font-weight: 600;
    color: ${({ isChecked }) => (isChecked ? '#103da8' : '#2b2d3f')};
    border: ${({ isChecked }) => (isChecked ? '2px solid #103da8' : '2px solid transparent')};
    border-bottom: ${({ isChecked, isLastChild }) => isChecked && isLastChild && 'none'};
    border-radius: ${({ isFirstChild, isLastChild, isChecked }) =>
        isFirstChild ? '12px 12px 0 0' : isLastChild && !isChecked ? '0 0 12px 12px' : '0'};
    background-color: ${({ isChecked }) => (isChecked ? '#eef1f6' : 'transparent')};
    background-image: url(${({ isChecked }) => (isChecked ? RadioCheckedIcon : RadioUncheckedIcon)});
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-size: 20px;
    user-select: none;
    cursor: pointer;
`;

const RadioBody = styled(motion.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 0 0 12px 12px;
`;

interface ControlOptionsProps {
    sessionId: string;
    selectedControlOption: WhereToControl;
    onSetSelectedControlOption: (option: WhereToControl) => void;
}

export default function ControlOptions({
    sessionId,
    selectedControlOption,
    onSetSelectedControlOption,
}: ControlOptionsProps): React.ReactElement {
    const handleControlsRadioClick = (option: WhereToControl) => {
        onSetSelectedControlOption(option);
    };

    return (
        <Container>
            <Subheading
                title={'Where will you control the Session?'}
                description={'Select one option.'}
                icon={SlidersIcon}
            />
            <RadioGroup>
                <Radio isFirstChild isChecked={selectedControlOption === 'controlOnThisDevice'}>
                    <RadioHeader
                        isFirstChild
                        onClick={() => handleControlsRadioClick('controlOnThisDevice')}
                        isChecked={selectedControlOption === 'controlOnThisDevice'}
                    >
                        Control on this device
                    </RadioHeader>
                </Radio>
                <Radio isLastChild isChecked={selectedControlOption === 'controlOnRemoteDevice'}>
                    <RadioHeader
                        isLastChild
                        onClick={() => handleControlsRadioClick('controlOnRemoteDevice')}
                        isChecked={selectedControlOption === 'controlOnRemoteDevice'}
                    >
                        Control on another device
                    </RadioHeader>
                    <AnimatePresence>
                        {selectedControlOption === 'controlOnRemoteDevice' && (
                            <RadioBody
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.15 }}
                            >
                                <ControlOnRemoteDevice sessionId={sessionId} />
                            </RadioBody>
                        )}
                    </AnimatePresence>
                </Radio>
            </RadioGroup>
        </Container>
    );
}
