import styled from '@emotion/styled';
import React from 'react';

import { useAudioDevices } from '../../useAudioDevices';

const Select = styled.select`
    border-radius: 8px;
`;

interface BrowserPlayerDeviceSelectorProps {
    device: string | undefined;
    onSetDevice: (deviceId: string) => void;
}
export const BrowserPlayerDeviceSelector = ({ device, onSetDevice }: BrowserPlayerDeviceSelectorProps) => {
    const { haveConsentToListDevices, outputDevices, getConsentToListDevices } = useAudioDevices();
    const deviceOptions =
        outputDevices.map((x) => {
            return {
                value: x.deviceId,
                label: x.label,
            };
        }) ?? [];
    const haveDevices = haveConsentToListDevices || deviceOptions.length > 1; // If we have device options already there is probably prior constent and we don't have to prompt for it again

    return haveDevices ? (
        <Select name="audio-device" value={device} onChange={(e) => onSetDevice(e.target.value)}>
            {deviceOptions.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </Select>
    ) : (
        <ConsentButton onClick={() => getConsentToListDevices()}>Manage Audio Device</ConsentButton>
    );
};

const ConsentButton = styled.button`
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: 1px solid #475467;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    color: #2b2d3f;
    cursor: pointer;
`;
