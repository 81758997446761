import { useCallback, useEffect, useState } from 'react';

export function useAudioDevices() {
    const [haveConsentToListDevices, setHaveConsentToListDevices] = useState(false);
    const [outputDevices, setOutputDevices] = useState<MediaDeviceInfo[]>([]);

    const refreshDevices = useCallback(async () => {
        if (!navigator.mediaDevices) {
            console.debug('No navigator.mediaDevices');
            return [];
        }
        const devices = await navigator.mediaDevices.enumerateDevices();
        console.debug('devices', { devices });

        const outputDevices = devices.filter((device) => {
            return device.kind === 'audiooutput';
        });
        console.debug('outputDevices', { outputDevices });
        setOutputDevices(outputDevices);
        return outputDevices;
    }, []);
    useEffect(() => {
        refreshDevices();
    }, [refreshDevices]);

    const getConsentToListDevices = useCallback(() => {
        if (!navigator.mediaDevices) {
            console.debug('No navigator.mediaDevices');
            return;
        }
        navigator.mediaDevices
            .getUserMedia({
                audio: true,
            })
            .then(() => setHaveConsentToListDevices(true))
            .then(refreshDevices)
            .catch(() => setHaveConsentToListDevices(false));
    }, [refreshDevices]);

    return {
        haveConsentToListDevices,
        outputDevices,
        getConsentToListDevices,
        refreshDevices,
    };
}
