import { SnackbarContent } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, styled, Theme } from '@material-ui/core/styles';
import React, { createContext, ReactNode, useState } from 'react';

import { useSnackbar, UseSnackbarReturn } from '@/hooks';
//TODO: move this to a style types file

type SnackbarProps = SnackbarStylesProps & {
    message: ReactNode;
    confirmText: string;
    open: boolean;
    closeSnackbar: () => void;
    onClickCallback?: () => void;
    className?: string;
    actionText?: string;
    actionCallback?: () => void;
    showCloseButton?: boolean;
};

type SnackbarStylesProps = {
    type: 'success' | 'warning' | 'error';
    isLongButton: boolean;
};

const useStyles = makeStyles<Theme, SnackbarStylesProps>((theme) => ({
    snackBarContentStyle: ({ type }) => ({
        display: 'inline-flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        gap: '12px',
        width: '100%',
        maxWidth: '600px',
        margin: 8,
        padding: '12px',
        borderRadius: 8,
        backgroundColor: type === 'success' ? theme.palette.success.main : type === 'warning' ? '#2B2D3F' : '#2B2D3F',
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight: 500,
        color: '#FFFFFF',
    }),
    message: () => ({
        flex: 1,
        display: 'inline-flex',
        alignItems: 'center',
        padding: '0 0 0 4px',
        fontSize: '14px',
        lineHeight: '19px',
        fontWeight: 500,
        color: '#FFFFFF',
    }),
    button: () => ({
        flex: 0,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 32,
        minWidth: 80,
        padding: '0 8px',
        border: 'none',
        borderRadius: 8,
        backgroundColor: 'rgba(255,255,255,0.1)',
        transition: 'background-color 0.15s ease',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 600,
        color: '#FFFFFF',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.2)',
        },
    }),
    closeButton: () => ({
        flex: 0,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 32,
        padding: '0 10px',
        border: 'none',
        borderRadius: 8,
        backgroundColor: 'rgba(255,255,255,0.1)',
        transition: 'background-color 0.15s ease',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 600,
        color: '#FFFFFF',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.2)',
        },
    }),
    actionWrapper: {
        margin: 0,
        padding: 0,
    },
    actions: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        gap: '8px',
    },
    messageWrapper: {
        padding: 0,
    },
    snackbarEntering: {
        animation: `$fadeInUp 0.5s ease-in-out`,
    },
    snackbarExiting: {
        animation: `$fadeOutDown 0.5s ease-in-out`,
    },
    '@keyframes fadeInUp': {
        '0%': {
            opacity: 0,
            transform: 'translateY(30px)',
        },
        '100%': {
            opacity: 1,
            transform: 'translateY(0)',
        },
    },
    '@keyframes fadeOutDown': {
        '0%': {
            opacity: 1,
            transform: 'translateY(0)',
        },
        '100%': {
            opacity: 0,
            transform: 'translateY(50px)',
        },
    },
}));

const WavepathsSnackbar = React.memo(
    ({
        message,
        confirmText,
        open,
        closeSnackbar,
        type,
        isLongButton,
        onClickCallback,
        className,
        actionText,
        actionCallback,
        showCloseButton,
    }: SnackbarProps): JSX.Element => {
        const classes = useStyles({ type, isLongButton });
        const [isExiting, setIsExiting] = useState(false);

        const handleClose = () => {
            setIsExiting(true);
            setTimeout(() => {
                closeSnackbar();
                setIsExiting(false);
            }, 800); // Match the duration of the exit animation
        };

        const closeBar = () => {
            onClickCallback && onClickCallback();
            handleClose();
        };

        const action = (
            <div className={classes.actions}>
                <button className={classes.button} onClick={actionCallback || closeBar}>
                    {actionText || confirmText}
                </button>
                {showCloseButton && (
                    <button className={classes.closeButton} onClick={handleClose}>
                        ✕
                    </button>
                )}
            </div>
        );

        const messageDiv = <div className={classes.message}>{message}</div>;

        return message ? (
            <Snackbar
                className={className}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={open}
                onClose={handleClose}
            >
                <SnackbarContent
                    className={`${classes.snackBarContentStyle} ${
                        isExiting ? classes.snackbarExiting : classes.snackbarEntering
                    }`}
                    message={messageDiv}
                    action={action}
                    classes={{ action: classes.actionWrapper, message: classes.messageWrapper }}
                />
            </Snackbar>
        ) : (
            <></>
        );
    },
);

const FixedSnackbar = styled(WavepathsSnackbar)({
    position: 'absolute',
});

export const GlobalSnackbarContext = createContext<ReturnType<typeof useSnackbar>>({
    setSnackbarContent: (content) => {
        console.debug({ info: 'setSnackbarContent', content });
    },
} as UseSnackbarReturn);

export const GlobalSnackbarWrapper: React.FC<{ children: ReactNode | ReactNode[] }> = ({ children }) => {
    const snackbarData = useSnackbar();
    return (
        <>
            <GlobalSnackbarContext.Provider value={snackbarData}>{children}</GlobalSnackbarContext.Provider>
            <FixedSnackbar
                type="warning"
                isLongButton={false}
                message={snackbarData.content}
                confirmText={'OK'}
                open={!!snackbarData.content}
                closeSnackbar={snackbarData.closeSnackbar}
                actionText={snackbarData.actionText}
                actionCallback={snackbarData.actionCallback}
                showCloseButton={snackbarData.showCloseButton}
            />
        </>
    );
};

export default WavepathsSnackbar;
