import styled from '@emotion/styled';
import React from 'react';

import Dialog from '@/components/Dialog/Dialog';
import DialogFooter from '@/components/Dialog/DialogFooter';
import DialogHeader from '@/components/Dialog/DialogHeader';

import ControlOnRemoteDevice from './controlOptions/ControlOnRemoteDevice';
import { CancelButton, Column, Columns, FooterActions } from './DeviceOptionsDialogComponents';
import PlayOnRemoteDevice from './playbackOptions/PlayOnRemoteDevice';

const Radio = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 0 0 2px #103da8;
    border-radius: 12px;
`;

const RadioHeader = styled.div`
    padding: 16px 20px 16px 16px;
    font-size: 16px;
    font-weight: 600;
    color: #103da8;
    border: 2px solid #103da8;
    border-bottom: none;
    border-radius: 12px 12px 0 0;
    background-color: #eef1f6;
    user-select: none;
`;

const RadioBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 0 0 12px 12px;
`;

const Checkbox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 0 0 2px #103da8;
    border-radius: 12px;
`;

const CheckboxHeader = styled.div`
    padding: 16px 20px 16px 16px;
    font-size: 16px;
    font-weight: 600;
    color: #103da8;
    border: 2px solid #103da8;
    border-bottom: none;
    border-radius: 12px 12px 0 0;
    background-color: #eef1f6;
    user-select: none;
`;

const CheckboxBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 0 0 12px 12px;
`;

interface RemoteOptionsDialogProps {
    isOpen: boolean;
    sessionId: string;
    broadcastIdentifier: string;
    isPlayerAppSupported: boolean;
    onClose: () => void;
}

export default function RemoteOptionsDialog({
    isOpen,
    sessionId,
    broadcastIdentifier,
    isPlayerAppSupported,
    onClose,
}: RemoteOptionsDialogProps): React.ReactElement {
    return (
        <Dialog isOpen={isOpen}>
            <DialogHeader text={'Controls and playback'} />
            <Columns>
                <Column>
                    <Radio>
                        <RadioHeader>Control on another device</RadioHeader>
                        <RadioBody>
                            <ControlOnRemoteDevice sessionId={sessionId} />
                        </RadioBody>
                    </Radio>
                </Column>
                <Column>
                    <Checkbox>
                        <CheckboxHeader>Play music on another device</CheckboxHeader>
                        <CheckboxBody>
                            <PlayOnRemoteDevice
                                broadcastIdentifier={broadcastIdentifier}
                                isPlayerAppSupported={isPlayerAppSupported}
                            />
                        </CheckboxBody>
                    </Checkbox>
                </Column>
            </Columns>
            <DialogFooter>
                <FooterActions>
                    <CancelButton onClick={onClose}>Close</CancelButton>
                </FooterActions>
            </DialogFooter>
        </Dialog>
    );
}
