import './NotificationStack.scss';

import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { MidSessionFeedbackSubmitted, MidSessionFeedbackType, Timestamped } from 'wavepaths-shared/core';

import { GlobalSnackbarContext } from '../Snackbar';
import { FeedbackDialog } from './FeedbackDialog';

type Dialog = { type: 'dialog'; key: string; feedbackType: MidSessionFeedbackType };

export type TimestampedFeedbackEventType = {
    type: MidSessionFeedbackType;
    timestamp: number;
    timeElapsedMs?: number;
};

interface NotificationStackProps {
    feedback?: TimestampedFeedbackEventType;
    onSubmitFeedback: (fb: FeedbackEvent) => void;
    onCancel: () => void;
}

export type FeedbackEvent = Timestamped<MidSessionFeedbackSubmitted>;

export const NotificationStack: React.FC<NotificationStackProps> = ({ feedback, onCancel, onSubmitFeedback }) => {
    const [text, setFeedbackText] = useState<string>('');
    const [selectedTags, setFeedbackTags] = useState<string[]>([]);

    const { setSnackbarContent } = useContext(GlobalSnackbarContext);

    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const [feedbackSubmittedType, setFeedbackSubmittedType] = useState('');

    const stackItem: Dialog | undefined =
        feedbackSubmitted || !feedback
            ? undefined
            : { type: 'dialog', key: `dialog=${feedback.type}-${feedback.timestamp}`, feedbackType: feedback.type };

    useEffect(() => {
        if (feedbackSubmitted) {
            setSnackbarContent(
                feedbackSubmittedType && feedbackSubmittedType === 'note'
                    ? 'Your note is logged and added to your session journal'
                    : 'Feedback complete, thank you',
                () => {
                    setFeedbackSubmitted(false);
                },
            );
        }
    }, [feedbackSubmitted]);

    useEffect(() => {
        setFeedbackText('');
        setFeedbackTags([]);
        setFeedbackSubmittedType('');
    }, [feedback?.type]);

    const onToggleFeedbackTag = (tag: string, toggleOn: boolean) => {
        if (toggleOn) {
            setFeedbackTags([...selectedTags, tag]);
        } else {
            setFeedbackTags(selectedTags.filter((t) => t !== tag));
        }
    };

    const onConcludeFeedback = () => {
        console.log('conclude', { feedback, text, tags: selectedTags });
        setFeedbackSubmittedType(feedback ? feedback?.type : '');
        setFeedbackSubmitted(true);

        if (feedback) {
            onSubmitFeedback({
                type: 'feedbackSubmitted',
                feedbackType: feedback.type,
                id: uuid(),
                timestamp: feedback.timestamp,
                text: text?.trim(),
                tags: selectedTags,
                timeElapsedMs: feedback.timeElapsedMs,
            });
        }
    };

    return (
        <>
            {!!stackItem && (
                <NotificationStackItem
                    key={stackItem.key}
                    item={stackItem}
                    feedbackText={text}
                    selectedTags={selectedTags}
                    onCancel={onCancel}
                    onToggleFeedbackTag={onToggleFeedbackTag}
                    onSetFeedbackText={setFeedbackText}
                    onSubmitFeedback={onConcludeFeedback}
                />
            )}
        </>
    );
};

interface NotificationStackItemProps {
    item: Dialog;
    feedbackText: string;
    selectedTags: string[];
    onToggleFeedbackTag: (tag: string, selected: boolean) => void;
    onSetFeedbackText: (text: string) => void;
    onSubmitFeedback: () => void;
    onCancel: () => void;
}
const NotificationStackItem: React.FC<NotificationStackItemProps> = ({
    item,
    selectedTags,
    feedbackText,
    onSetFeedbackText,
    onToggleFeedbackTag,
    onSubmitFeedback,
    onCancel,
}) => {
    return (
        <FeedbackDialog
            headerContent={getDialogHeaderContent(item.feedbackType)}
            feedbackTagOptions={getFeedbackTagOptions(item.feedbackType)}
            selectedFeedbackTags={selectedTags}
            onToggleTag={onToggleFeedbackTag}
            text={feedbackText}
            onSetFeedbackText={onSetFeedbackText}
            onSubmit={onSubmitFeedback}
            onCancel={onCancel}
        />
    );
};

function getDialogHeaderContent(feedbackType: MidSessionFeedbackType): string {
    switch (feedbackType) {
        case 'helpfulMusic':
            return 'Why was the music helpful?';
        case 'unhelpfulMusic':
            return 'Why was the music unhelpful?';
        case 'note':
            return 'Leave a note for later';
        case 'issue':
            return 'What is the issue?';
        default:
            return '';
    }
}

function getFeedbackTagOptions(feedbackType: MidSessionFeedbackType): string[] {
    switch (feedbackType) {
        case 'helpfulMusic':
            return ['Music enjoyable', 'Music matches emotion well', 'Music supportive', 'Music quality high', 'Other'];
        case 'unhelpfulMusic':
            return ['Dislike music', 'Music irritating', 'Music unfriendly', 'Music quality low', 'Other'];
        case 'note':
            return [
                'Autobiographical',
                'Spiritual',
                'Joy',
                'Peace',
                'Grief',
                'Tearful',
                'Tension',
                'Anger',
                'Love',
                'Challenging',
                'Physical',
                'Insightful',
                'Emotional Release',
                'Breakthrough',
                'Anxiety',
                'Dissociation',
                'Other',
            ];
        case 'issue':
            return [
                'Sound cut out unexpectedly',
                'Screen crashed/frozen',
                'Unable to perform action',
                'Something doesn’t look right',
                'Other',
            ];
        default:
            return ['Placeholder 1', 'Placeholder 2'];
    }
}
