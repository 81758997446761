import React, { useContext, useEffect } from 'react';
import { ReactElement } from 'react';

import { GlobalSnackbarContext } from '@/components/Snackbar';
import { getCEAColour } from '@/util/ceaColours';

import { Connection } from '../../../common/hooks/useSessionTick';
import { Queueable } from '../actionQueue/useActionQueue';
import { useRemoteCEA } from '../ceaButtons/useCEA';
import IntensitySlider from './IntensitySlider';
import { useRemoteDepth } from './useDepth';

export interface IIntensityControllerContainer {
    connection: Connection;
    opacity: number;
    queueFunction: (queueable: Queueable) => void;
    sliderVisible: boolean;
}

export const SNACKBAR_ERROR_COPY =
    'You are too close to the end of your current wave to make that change. Please wait or skip to the next wave and then make your adjustment';

export const SNACKBAR_DISABLED_COPY =
    'You cannot change intensity while in this wave. To change intensity, move to a new emotionality, or skip to a generative wave.';

function IntensityController({
    connection,
    opacity,
    queueFunction,
    sliderVisible,
    ...props
}: IIntensityControllerContainer): ReactElement | null {
    const depthState = useRemoteDepth(connection, queueFunction);
    const depthStateError: string | null | undefined = depthState !== 'loading' ? depthState.error : null;
    const cea = useRemoteCEA(connection, queueFunction);
    const colorHex = cea !== 'loading' && cea.targetCea ? getCEAColour(cea.targetCea) : '#D3D3D3';
    const colorTransitionTimeInSecs = cea !== 'loading' ? cea.transitionTimeSecs ?? 0 : 0;
    const { setSnackbarContent } = useContext(GlobalSnackbarContext);

    useEffect(() => {
        if (depthStateError) {
            setSnackbarContent(SNACKBAR_ERROR_COPY);
        }
    }, [depthStateError, setSnackbarContent]);

    if (depthState === 'loading' || cea === 'loading') return null;

    const { targetDepth, currentDepth, transitionTimeSecs, setTargetDepth } = depthState;

    const handleDisabledClick = () => setSnackbarContent(SNACKBAR_DISABLED_COPY);

    const pointerEvents = opacity == 1 ? 'initial' : 'none';

    return (
        <>
            {sliderVisible && (
                <div
                    style={{
                        opacity,
                        pointerEvents,
                    }}
                >
                    <IntensitySlider
                        targetIntensity={targetDepth}
                        currentIntensity={currentDepth}
                        currentIntensityTransitionTimeSecs={transitionTimeSecs}
                        disabled={false}
                        onDepthChange={setTargetDepth}
                        onClickWhenDisabled={handleDisabledClick}
                        colorHex={colorHex}
                        colorHexTransitionTimeSecs={colorTransitionTimeInSecs}
                        {...props}
                    />
                </div>
            )}
        </>
    );
}

export default IntensityController;
