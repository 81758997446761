import firebase from 'firebase';

import configs from '../../configs';
import axios from '../util/axios';

export const FREUD_BASE_URL = configs.freud.BASE_URL;

export type SupportPostData = {
    id: string;

    tag?: string;
    shortDescription?: string;
    description?: string;
    /**
     * ISO 8601 string
     */
    publishedAt?: string;

    archivedAt?: boolean;
    /**
     * ISO 8601 string
     */
    createdAt: string;
    /**
     * ISO 8601 string
     */
    updatedAt: string;
};

export type SupportPostUpsertData = Pick<SupportPostData, 'tag' | 'shortDescription' | 'description' | 'publishedAt'>;

export type SupportPostsGetListQuery = {
    includeArchived?: boolean;
};

export const getSupportPosts = async (
    fbUser: firebase.User,
    query?: SupportPostsGetListQuery,
): Promise<SupportPostData[]> => {
    let url = FREUD_BASE_URL + `/support_posts`;
    if (query?.includeArchived) {
        url += '?includeArchived=true';
    }
    try {
        const response = await axios(url, {
            method: 'GET',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error fetching');
        }

        return response.data;
    } catch (e) {
        console.debug('Error', e);
        throw e;
    }
};

export const createSupportPost = async (fbUser: firebase.User, article: SupportPostUpsertData): Promise<void> => {
    const url = FREUD_BASE_URL + `/support_posts`;
    try {
        const response = await axios(url, {
            method: 'POST',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
            data: article,
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error fetching');
        }

        return response.data;
    } catch (e) {
        console.debug('Error', e);
        throw e;
    }
};

export const updateSupportPost = async (
    fbUser: firebase.User,
    id: string,
    article: SupportPostUpsertData,
): Promise<void> => {
    const url = FREUD_BASE_URL + `/support_posts/${id}`;
    try {
        const response = await axios(url, {
            method: 'PATCH',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
            data: article,
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error fetching');
        }

        return response.data;
    } catch (e) {
        console.debug('Error', e);
        throw e;
    }
};

export const archiveSupportPost = async (fbUser: firebase.User, id: string): Promise<void> => {
    const url = FREUD_BASE_URL + `/support_posts/${id}/archive`;
    try {
        const response = await axios(url, {
            method: 'POST',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error fetching');
        }

        return response.data;
    } catch (e) {
        console.debug('Error', e);
        throw e;
    }
};

export const unarchiveSupportPost = async (fbUser: firebase.User, id: string): Promise<void> => {
    const url = FREUD_BASE_URL + `/support_posts/${id}/unarchive`;
    try {
        const response = await axios(url, {
            method: 'POST',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error fetching');
        }

        return response.data;
    } catch (e) {
        console.debug('Error', e);
        throw e;
    }
};
